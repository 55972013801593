const lib = {
    req(req, store, router) {
        if (typeof req.data == 'undefined') {
            req.data = {data:{}};
        }
        if (store.state.pay_pass) {
            req.data.data.cabinet_pay_password = store.state.pay_pass;
            store.commit('PAY_PASS', false);
        }
        req.data.device_fingerprint = store.state.fingerprint;
        req.data.lang = localStorage.getItem("lang");
        if (req.data.no_loader) {
            delete req.data.no_loader;
        } else {
            store.commit('LOADER', true);
        }
        console.log(req);
    },
 
    
    res(res, store, router) {
        console.log(res);
        store.commit('LOADER', false);
        if (res.config.method == 'get') {
            return
        }
        if (res.data.isLogged === true) {
            store.commit('IS_LOGGED', res.data.isLogged);
            // if (!store.state.domains && res.data.body.domains) {
            //     store.commit('DOMAINS', res.data.body.domains);
            // }
        } else if (res.data.isLogged === false) {
            // if (!store.state.domains && res.data.body.domains) {
            //     store.commit('DOMAINS', res.data.body.domains);
            // }
            store.commit('IS_LOGGED', res.data.isLogged);
            // store.commit('USER_DATA', {});
            if (!res.data.body.alter) {
                store.commit('MODAL', {
                    action: false,
                    type: false,
                    content: false,});
            }
            store.commit('DARK_WRAPPER', false);
        } else {
            router.push('/unexpected');
        }
        if (res.data.modal) {
            if (res.data.modal == 'repeat') {
                res.data.body.repeat_url = res.config.url;
            }
            if (this.inArray(res.config.url, ['SearchMember', 'GetComiss', 'checkUnique']) && res.data.modal != 'questionnaire') {
                if (res.data.risk_form) {
                    store.commit('MODAL_ACTION', true);
                    store.commit('MODAL_TYPE', 'questionnaire');
                    store.commit('MODAL_CONTENT', res.data.risk_form);
                }
                return
            }
            store.commit('MODAL_ACTION', true);
            store.commit('MODAL_TYPE', res.data.modal);
            store.commit('MODAL_CONTENT', res.data.body);
        }
        if (res.data.error) {
            store.commit('ERROR', res.data.error);
            if (res.data.error.error_code != '4020' && res.data.error.error_code != '4010') {
                alert(`ERROR: ${res.data.error.error_text}`);
            }
        }
        if (res.data.body.updating) {
            if (res.data.body.updating.keyts) {
                store.commit('USER_DATA_KEYTS', res.data.body.updating.keyts);
                store.commit('GLOBAL_KEY_KEYT');
            }
            if (res.data.body.updating.templates) {
                store.commit('USER_DATA_TEMPLATES', res.data.body.updating.templates);
            }
            if (res.data.body.updating.user_info) {
                let inf = res.data.body.updating.user_info; 
                store.commit('USER_DATA_INFO', inf);
                if (inf.avatar) {
                    let av = "";
                    if (inf.avatar.value.length > 0) {
                        av = inf.avatar.value[0].body;  
                    }
                    store.commit('USER_DATA_AVATAR', av);
                }
            }
        }
        if (res.data.risk_form) {
            store.commit('MODAL_ACTION', true);
            store.commit('MODAL_TYPE', 'questionnaire');
            store.commit('MODAL_CONTENT', res.data.risk_form);
        }
    },

    err(err, store, router) {
        store.commit('LOADER', false);
        if (err.config.method == 'get') {
            return
        }
        if (err.response) {
            alert(`CODE: ${err.response.status}\n ${err.response.statusText}`);
        } else {
            alert('Unexpcected Http Error');
        }
    },

    checkRegExp(str, reg) {
        let regexp = new RegExp(reg);
        return regexp.test(str);
    },

    alertE(store, body) {
        store.commit('MODAL_ACTION', true);
        store.commit('MODAL_TYPE', 'answer');
        store.commit('MODAL_CONTENT', body);
    },

    isInt(x) {
        var y = parseInt(x);
        if (isNaN(y))
            return false;
        return x == y && x.toString() == y.toString();
    },

    HardcodeCurrPrecision(curr, amount) {

        if (this.inArray(curr, ['RUR', 'USD', 'EUR', 'VLS']) != -1)
            return 2;
        else if (this.inArray(curr, ['BTC']) != -1)
            return 8;
        else if (this.inArray(curr, ['ETH']) != -1)
            return 10;
        else
            return amount.split('.').pop().length || 2;
    },

    inArray(curr, arr) {
        if (arr.indexOf(curr) != -1) {  
            return true;
        } else {
            return false;
        }
    },

    RandomDigit(len) {
        var r;
        var digit = new Array("0", "1", "2", "3", "4", "5", "6", "7", "8", "9");
    
        let pasw = "";
    
        for (var i = 0; i < len; i++) {
            r = Math.floor(Math.random() * 9);
            pasw += digit[r];
        }
        return pasw;
    },

    cRound(num) {
        return Math.floor(num * 100) / 100;
    }

}

export default lib;