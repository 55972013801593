<template>
    <div class="row container-fluid mt-1 g-3 p-0 align-items-center input">
        <div class="col-sm-3 p-0">
            <label :for="id" class="col-form-label input-label">{{this.label}}</label>
        </div>
        <div class="col-sm-9 p-0 input-wrapper">
            <InputDateSimple :id="id" :dataValue='this.dataValue' :expFormat="this.expFormat" :disabled="this.disabled"  :type='this.type' @updateParentState="this.setValue"/>
            <div class="d-flex flex-row align-items-center justify-content-end">
                <Popper v-if="this.hint" class="bs-tooltip" :content='this.hint'/>
                <div v-else-if="this.unvisible" class="mw34"></div>
                <Popper v-if="this.verification" :custom="'item-i check'" :content="$t('views.InfoReg.3')"/>
                <div v-else-if="this.unvisible || this.vMark" class="mw34"></div>
                <SlotPopper v-if="this.unvisible"  :content="$t('views.InfoReg.4')">
                    <SwitchSimple :id="id" :dataValue='this.unvisibleValue' :type="'sm'" class="ml-1" @updateParentState="this.setUnvisibleValue"/>
                </SlotPopper>
            </div>
        </div>
    </div>
</template> 

<script>
import InputDateSimple from '@/components/service/InputDateSimple';
import SwitchSimple from '@/components/service/SwitchSimple';
import Popper from '@/components/service/Popper';
import SlotPopper from '@/components/service/SlotPopper';

export default {
    components: {
        Popper,
        InputDateSimple,
        SlotPopper,
        SwitchSimple
    },
    data() {
        return {
            value: this.dataValue ? this.dataValue : false,
            unvisibleValue: this.unvisibleDataValue ? this.unvisibleDataValue : '',
        }
    },
    props: {
        id: [String, Boolean],
        label: [String],
        hint: [String],
        dataValue: [String, Boolean],
        verification: [String, Boolean],
        type: {
            default: 'date',
            type: [String, Boolean]
        },
        disabled: [Boolean],
        unvisible: [Boolean],
        unvisibleDataValue: [String, Boolean], 
        vMark: [Boolean],
        expFormat: {
            default: false,
            type: [Boolean]
        },
    },
    methods: {
        setValue(arg) {
            this.$emit('updateParentState', {
                id: arg.id,
                value: arg.value
            })
        },
        setUnvisibleValue(arg) {
            this.$emit('updateParentState', {
                id: arg.id,
                unvisible: true,
                value: arg.value
            })
        },
    },

}
</script>

<style lang="scss" scoped>
.input {
    font-family: 'HelveticaNeue roman';
    &-wrapper {
        position: relative;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-items: center;
        justify-content: space-between;
    }
}
.mt-1 {
    margin: 0 !important;
}
.require {
    color: red;
}
</style>