<template>
    <button v-if="this.disabled == true"  :style='this.style' v-bind:class="this.type" disabled class='btn disabled'>
        <i v-if='this.icon_i' class='item-i mr-1' :class='this.icon_i'></i>
        {{this.name}}
    </button>
    <button v-else class='btn' :style='this.style' v-bind:class="this.type">
        <i v-if="this.icon_i.length > 1" class='item-i mr-1' :class='this.icon_i'></i>
        {{this.name}}
    </button>
</template>

<script>
export default {
    props: {
        name: [String],
        type: {
            type: String,
            default: 'btn-primary'
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        icon_i: {
            type: String,
            default: '',
        },
        style: {
            type: String,
            default: '',
        }
    },
}
</script>

<style lang="scss" scoped>
.btn {
    box-shadow: none;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}
.btn .item-i {
    height: 1.3em;
    width: 1.3em;
}
.btn-warning {
    color: white;
    background-color: $color-acent;
    border-color: $color-acent;
} 
.btn-warning:hover:not(.disabled) {
    color: white;
    background-color: $color-acent;

    filter: saturate(200%);
}
.disabled {
    filter: saturate(10%);
}
.btn-outline-warning {
    color: $color-acent;
    border-color: $color-acent;
}
.btn-outline-warning:focus {
    color: $color-acent;
    background-color: white;
}
.btn-outline-warning:hover:not(.disabled) {
    color: white;
    background-color: $color-acent;
    border-color: $color-acent;
}
.btn-success {
    color: white;
    background-color: $color-green;
    border-color: $color-green;
}
.btn-success:hover:not(.disabled) {
    color: white;
    background-color: $color-green;

    filter: saturate(150%);
}
// .btn-success:focus {
//     color: $color-green;
//     background-color: white;
// }

.btn-outline-success {
    color: $color-green;
    background-color: white;
    border-color: $color-green;
}
.btn-outline-success:hover:not(.disabled) {
    color: white;
    background-color: $color-green;
}
.btn-outline-success:focus {
    color: white;
    background-color: $color-green;
}

.btn-outline-red {
    color: $color-warning;
    border-color: $color-warning;
}
.btn-outline-red:focus {
    color: $color-warning;
    background-color: white;
}
.btn-outline-red:hover:not(.disabled) {
    color: white;
    background-color: $color-warning;
    border-color: $color-warning;
}
.btn-href {
    color: white;
    background-color: $color-href;
    border-color: $color-href;
}
.btn-href:hover:not(.disabled) {
    color: white;
    background-color: $color-href;

    filter: saturate(150%);
}
.btn-outline-href {
    color: $color-href;
    border-color: $color-href;
}
.btn-outline-href:focus {
    color: $color-href;
    background-color: white;
}
.btn-outline-href:hover:not(.disabled) {
    color: white;
    background-color: $color-href;
    border-color: $color-href;
}

.btn-white-warning {
    color: white;
    background-color: $color-acent;
    border-color: white;
}
.btn-white-warning:hover:not(.disabled) {
    color: white;
    background-color: $color-acent;
    border-color: white;

    filter: saturate(150%);
}
.btn-outline-white-warning {
    color: $color-acent;
    background-color: white;
    border-color: $color-acent;
}
.btn-outline-white-warning:focus {
    color: white;
    background-color: $color-acent;
    border-color: white;
}
.btn-outline-white-warning:hover:not(.disabled) {
    color: white;
    background-color: $color-acent;
    border-color: white;
}

.btn-dashed, .btn-dashed:hover:not(.disabled)  {
    border: 2px dashed #d6d4d4;
    border-radius: 30px;
}

.btn-dashed-active, .btn-dashed-active:hover:not(.disabled)  {
    border: 2px solid $color-green;
    border-radius: 30px;
}
</style>