<template>
    <div>
        <div class='d-flex justify-content-between neue mb-3'>
            <h4 class="mb-0 mt-2">{{ this.title }}</h4>
            <i class='big item-i x_red pointer' v-on:click='this.close'></i>
        </div>

        <div class='mb-2'> 
            <span class="neue-bold">{{ this.instruction }}</span>
        </div>
        <!-- <div class='mb-1' v-if="this.title">
            <p class='mb-0'>{{ this.title }}</p>
        </div> -->
        <div class='mb-3' v-if="this.fields">
            <GetInputs :fields='this.fields' :doublebus="true" class='tst' :dataValues='this.fields_values' @blureInput="this.blureInputs" @updateParentState="this.updateNumState"/>
        </div>

        <div class='mb-3' v-else>
            <p class='mb-0'>{{ $t('components.service.modal.Questionnaire.2') }}</p>
        </div>

        <div class='mb-3' v-if="this.warn">
            <p class='mb-0 color-warning'>{{ this.warn }}</p>
        </div>
        
        <div class="d-flex container-fluid g-3 align-items-center justify-content-center input mr-0">
            <div class="col-sm-2">
                <Button :name="$t('components.service.modal.Questionnaire.1')" type='btn-warning btn-lg' :disabled="!this.ready" class="col-12" v-on:click="this.sendQuestionnaire"/>
            </div>
        </div>
    </div>
</template>
  
<script>
import GetInputs from '@/components/service/GetInputs';
import Button from '@/components/service/Button';
  
  export default {
      components: {
        GetInputs,
        Button
      },
      data() {
        return {
            content: this.$store.state.modal.content,
            fields: this.$store.state.modal.content.antifraud_determine_risk_form_fields,
            title: this.$store.state.modal.content.antifraud_determine_risk_form_fields_name,
            instruction: this.$store.state.modal.content.antifraud_determine_risk_form_instruction,
            fields_values: {},
            ready: false,
            warn: false,
        }
      },
      props: {
        close: [Function],
      },
      methods: {
        blureInputs(arg) {
            this.ready = this.warnWatch();
        },
        warnWatch() {
            let r = true;
            this.fields.forEach(e => {
                if (e.invalid == true) {
                    r = false;
                }
            })
            return r;
        },
        updateNumState(state) {
            this.fields_values[state.id] = state.value;
        },
        sendQuestionnaire() {
            let params = Object.assign({}, this.fields_values);
            this.warn = false;
            this.axios.post('SendQuestionnaire', {data: params}).then( res => {
                if (res.data.body.result != '0') {
                    this.warn = res.data.body.result_text;
                } else {
                    this.close();
                    this.$store.dispatch('reRender');
                }
            }
            )
        }
      },
      mounted() {
        console.log(this.$store.state.modal.content);
      },
  }
  </script>
