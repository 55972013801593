<template>
    <div ref='d_down' class="dropdown">
        <a class="btn btn-white-warning dropdown-toggle" :style='this.style' v-on:click="this.open" href="#" role="button">
            {{this.label}}
        </a>

        <ul v-if="this.active"  :style='"max-height: " + this.listHeight + "px; top: 2.17rem; " + this.opt_style' :class="[this.transformUp ? 'open-up' : '', this.position]" class="dropdown-menu">
            <li v-for="(row, index) in this.menu" :key="index" >
                <a class="dropdown-item" href="#" v-on:click="(e) => {row.handler(); this.close(e);}">{{row.name}}</a>
            </li>
        </ul>
    </div>
</template> 

<script>
import { gsap } from "gsap"; 

export default {
    props: {
        menu: [Array],
        label: {
            default: '',
            type: [String, Boolean]
        },
        style: {
            default: '',
            type: [String, Boolean]
        },
        position: {
            default: '',
            type: [String, Boolean]
        },
        opt_style: {
            default: '',
            type: [String, Boolean]
        }
    },
    data() {
        return {
            active: false,
            transformUp: false,
        }
    },
    mounted() {
        console.log(this.opt_width)
    },
    methods: {
        open() {
            this.listHeight = this.getHeight();
            console.log(this.listHeight)
            this.transformUp = Number(this.listHeight) < 70
            if (this.transformUp) {
                this.listHeight = '200'
            }
            this.active = true;
            document.querySelector('body').addEventListener('mouseover', this.close);
        },
        close(e) {
            if (!this.$el.contains(e.target) || e.type == 'click') {
                this.active = false;
                document.querySelector('body').removeEventListener('mouseover', this.close);
            }
        },
        enter(el) {
            gsap.fromTo(el, {opacity: 0.1, height: '95%'}, {opacity: 1, height: 'auto', duration: 0.1});
        },
        leave(el, done) {
            gsap.fromTo(el, {opacity: 1}, {opacity: 0.1, duration: 0.2, onComplete: done});
        },
        getHeight() {
            let list = this.$refs.d_down.getBoundingClientRect();
            // let box = document.querySelector('#app').getBoundingClientRect();
            let box = {height: window.innerHeight};
            return String((box.height + window.pageYOffset) - (list.bottom + window.pageYOffset) - 10);
        },
    },
}
</script>

<style lang="scss" scoped>
.dropdown-menu {
    display: block;
    z-index: 2001;
    overflow: auto;
}
.dropdown a{
    min-height: 38px;
    display: block;
}
.btn {
    box-shadow: none;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}
.btn .item-i {
    height: 1.3em;
    width: 1.3em;
}
.btn-warning {
    color: white;
    background-color: $color-acent;
    border-color: $color-acent;
}
.btn-warning:hover:not(.disabled) {
    color: white;
    background-color: $color-acent;

    filter: saturate(200%);
}
.disabled {
    filter: saturate(10%);
}
.btn-outline-warning, .btn:focus {
    color: $color-acent;
    border-color: $color-acent;
    border-color: $color-acent;
    background-color: transparent;
}
.btn-outline-warning:hover:not(.disabled) {
    color: white;
    background-color: $color-acent;
    border-color: $color-acent;
}
.lg-left {
    @media (min-width: 991px) {
        left: 1%;
    }
}
.right {
    right: 1%;
}
.open-up {
  transform: translateY(-117%);
  border-radius: 0.25rem;
  border-top: 1px solid #ced4da;
}
.btn-white-warning {
    color: white;
    background-color: $color-acent;
    border-color: white;
}
.btn-white-warning:hover:not(.disabled) {
    color: white;
    background-color: $color-acent;
    border-color: white;

    filter: saturate(150%);
}
.btn-white-warning:focus {
    color: white;
    background-color: $color-acent;
    border-color: white;
}
</style>