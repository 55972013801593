<template>
  <div class='wrapper' :key="this.$store.state.globalKey">
    <Modal v-if="this.$store.state.modal.action"/>
    <transition @enter="enter" @leave="leave">
      <div v-if="this.$store.state.darkWrapper" class='dark-wrapper'></div>
    </transition>
    <Loading :active.sync="this.$store.state.loader" 
        :can-cancel="false" />
    <div class='page-wrapper'>
      <router-view v-if="this.$store.state.userData" />
      
    </div>
  </div>
</template>

<script>
import UseClientJs from '@/includes/fingerprint.js';
import Loading from 'vue-loading-overlay';
import Modal from '@/components/service/Modal';
import Login from '@/views/Login';
import 'vue-loading-overlay/dist/css/index.css';
import { gsap } from "gsap";

export default {
  components : {
    Loading,
    Modal,
    Login,
  },
  data() {
    return {
    }
  },
  created() {
    this.setMetaColor();
    let client = new UseClientJs();
	  let fingerPrint = client.getRetailFingerptint();
    this.$store.commit('FINGERPRINT', fingerPrint);
    this.$store.commit('RESIZE', {
        w: document.documentElement.clientWidth,
        h: document.documentElement.clientHeight
    })
    this.getSize();
    window.addEventListener('resize', this.updateSize);
    this.isLogged();
    let ua = navigator.userAgent;
    // console.log(ua)
    if (ua.includes('ppWebViewApp')) {
      this.$store.commit('WEB_VIEW', 'ppWebViewApp');
      if (ua.includes('ppTopBangs/')) {
        let i = ua.split('ppTopBangs/')[1]; 
        this.$store.commit('WEB_VIEW_STATUS_BAR', i);
      }
    }
  }, 
  mounted() {
    let url = new URL(window.location.href);
    let id = url.searchParams.get("id");
    let crt_num = url.searchParams.get("crt_num");
    let control_code = url.searchParams.get("control_code");
    if (window.location.pathname == "/make_good.php" && id && crt_num && control_code) {
        this.axios.post('DoConfirm', {data: {
                'id':id,
                'crt_num':crt_num,
                'control_code':control_code
            }});
      }
  },
  methods: {
    isLogged() {
      this.$store.dispatch('isLogged', {i18n: this.$i18n});
    },
    setMetaColor() {
      let themeColor = document.querySelector("meta[name='theme-color']");
      themeColor.content = process.env.VUE_APP_THEMECOLOR;
    },
    updateSize() {
      this.$store.commit('RESIZE', {
        w: document.documentElement.clientWidth,
        h: document.documentElement.clientHeight
      })
      this.getSize();
    },
    getSize() {
      if (document.documentElement.clientWidth < 576) {
        this.$store.commit('IS_MOBILE', true);
      } else {
        this.$store.commit('IS_MOBILE', false);
      }
    },
    enter(el) {
      gsap.fromTo(el, {opacity: 0}, {opacity: 0.1, duration: 0.2});
    },
    leave(el, done) {
      gsap.fromTo(el, {opacity: 0.1}, {opacity: 0, duration: 0.2, onComplete: done});
    },
  },
}
</script>

<style lang="scss">
@import "@/includes/_fonts.scss";
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

html {
  font-size: 16px;
  font-family: HelveticaNeue, Roboto;
  // @media (max-width: 1500px) {
  //   font-size: 14px;
  // }
  // @media (max-width: 1350px) {
  //   font-size: 12px;
  // }
  // @media (max-width: 1120px) {
  //   font-size: 11px;
  // }
  // @media (max-width: 1000px) {
  //   font-size: 10px;
  // }
}
.grey {
  color: $color-grey;
}
.color-menu {
  color: $color-menu;
}
.color-green {
  color: $color-green;
}
.color-system-green {
  color: $color-system-green;
}
.color-acent {
  color: $color-acent;
}
.color-warning {
  color: $color-warning;
}
.color-newsg {
  color: $color-newsg;
}
.color-yellow {
  color: $color-yellow;
}
.href {
  color: $color-href !important;
}
.pl-0 {
  padding-left: 0 !important;
}
.pr-0 {
  padding-right: 0 !important;
}
.pr-3 {
  padding-right: 1.4rem !important;
}
.pl-3 {
  padding-left: 1.4rem !important;
}
.pb-0 {
  padding-bottom: 0rem !important;
}
.pb-20 {
  padding-bottom: 3rem !important;
}
.pt-0 {
  padding-top: 0rem !important;
}
.pt-1 {
  padding-top: 0.7rem !important;
}
.bp-1 {
  padding-bottom: 2rem !important;
}
.bp-2 {
  padding-bottom: 3rem !important;
}
.pb-3 {
  padding-bottom: 1.5rem !important;
}
.pb-4 {
  padding-bottom: 6rem !important;
}
.pr-0 {
  padding-right: 0 !important;
}
.pl-1 {
  padding-left: 0.7rem !important
}
.pl-2 {
  padding-left: 1.4rem !important
}
.pl-4 {
  padding-left: 3.6rem !important
}
.my-1 {
  margin: 0.45rem 0 !important;
}
.my-2 {
  margin: 1.2rem 0 !important;
}
.mb-0 {
  margin-bottom: 0 !important;
}
.mb-02 {
  margin-bottom: 0.2rem !important;
}
.mb-1 {
  margin-bottom: 0.4rem !important;
}
.mb-3 {
  margin-bottom: 1.5rem !important;
}
.mt-1 {
    margin: 0 !important;
}
.mt-2 {
    margin-top: 0.7rem !important;
}
.mt-3 {
    margin-top: 1rem !important;
}
.mt-0 {
  margin-top: 0 !important;
}
.pr-1 {
  padding-right: 0.7rem !important;
}
.pr-2 {
  padding-right: 1.4rem !important; 
}
.mr-0 {
    margin-right: 0 !important;
}
.mr-05 {
  margin-right: 0.35rem !important;
}
.mr-1 {
  margin-right: 0.7rem !important;
}
.mr-25 {
  margin-right: 0.25rem !important;
}
.mr-2 {
  margin-right: 1.4rem !important;
}
.ml-0 {
  margin-left: 0 !important;
}
.ml-1 {
  margin-left: 0.25rem !important;
}
.ml-2 {
  margin-left: 0.7rem !important;
}
.ml-3 {
  margin-left: 1.4rem !important;
}
.ml-4 {
  margin-left: 2.2rem !important;
}
.max-h-1 {
  max-height: 1rem !important;
}
.h100 {
  height: 100% !important;
}

.bs-tooltip-auto {
  z-index: 5000 !important;
}

.sm-no-p {
  @media (max-width: 767px) {
    padding: 0 !important;
  }
}
.fs_07 {
  font-size: 0.7rem;
}
.fs_08 {
  font-size: 0.8rem;
}
.fs_085 {
  font-size: 0.85rem;
}
.fs_09 {
  font-size: 0.9rem;
}
.fs_10 {
  font-size: 1rem;
}
.fs_11 {
  font-size: 1.1rem;
}
.fs_12 {
  font-size: 1.2rem;
}
.fs_13 {
  font-size: 1.3rem;
}
.fs_14 {
  font-size: 1.4rem;
}
.fs_15 {
  font-size: 1.5rem;
}
.fs_17 {
  font-size: 1.7rem;
}

.wh-23 {
  @media (min-width: 992px) {
      width: 23%;
  }
}
.wh-74 {
  @media (min-width: 992px) {
      width: 73%;
  }
}
.wh-48 {
  @media (min-width: 992px) {
      width: 48%;
  }
}

a {
  text-decoration: none !important;
  color: #000;
}
b {
  font-weight: bold;
}
body:after{
    content:"";
    position:fixed; /* stretch a fixed position to the whole screen */
    top:0;
    height:100%; /* fix for mobile browser address bar appearing disappearing */
    left:0;
    right:0;
    z-index:-1; /* needed to keep in the background */
    // background:url($bg-image) center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    background-color: transparent;
}
.before-bg:after {
  background:url($bg-image) center center;
  background-repeat: no-repeat;
  background-size: cover;
}
.beforew-bg {
  background-color: transparent !important;
}
.afterw-bg {
  background-color: $color-background !important;
}
.after-bg:after {
  background-color: $color-menu !important;
  background-image: none !important;
}
.break-word {
  word-wrap: break-word;
}
#app {
  color: #2c3e50;
  // background-color:transparent;
  min-height: 100%;
  width: 100%;
}
.neue-bold {
  font-family: 'HelveticaNeue bold', 'Roboto bold', sans-serif !important;
}
.neue-roman {
  font-family: 'HelveticaNeue roman', 'Roboto', sans-serif !important;
}
.neue {
  font-family: 'HelveticaNeue', 'Roboto medium', sans-serif !important;
}
.light {
  font-family: "HelveticaNeue light", "Roboto light", sans-serif !important;
}
h4 {
    font-family: 'HelveticaNeue bold', 'Roboto bold', sans-serif;
}
h5, h3 {
  font-family: 'HelveticaNeue roman', 'Roboto medium', sans-serif;
}

.href, .pointer {
  cursor: pointer;
}
.btn_mb {
  text-align: center !important;
}
.btn_mb button, button.btn_mb {
  width: 90%;
  margin-top: 1rem;
}
.wrapper {
  position: relative;
  min-height: 100vh;
  background-color: $color-background;
}
.dark-wrapper {
  bottom: 0;
  top: 0;
  width: 100%;
  background-color: #000;
  position: absolute;
  z-index: 2000;
}
.page-wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  @media screen and (max-width: 425px) {
    // margin: 0.7rem;
  }
}
.pr {
  margin-top: 42px;
}
.mw34 {
  min-width: 35px;
}
.mw40 {
  min-width: 40px;
}
.content {
  background-color: #FFFFFF;
  box-shadow: 1px 2px 4px 1px rgb(1 52 23 / 10%);
  border-radius: 12px;
  margin-bottom: 2rem;
}
    .buttons-container {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: center;
        width: 100%;
        margin-top: 1rem;
        padding-bottom: 1rem;
        button {
            margin: 0 0.5rem;
        }
    }

.footer {
    background-color: $color-menu-active;;
    font-size: 1rem;
    height: 30px;
    width: 100%;
    position: fixed;
    bottom: 0;
    &-text {
        font-size: 1.1rem;
    }
}
.require {
    color: red;
}
</style>