<template>
    <div v-if='this.fields'> 
        <div class='container-fluid p-0' v-for="(field, key) in this.fields" :key="key">
            <div v-if="this.onAlias">
            <Switch v-if='field.type_field == "BOOL"'
                :label='field.name' 
                :require="field.obligatory == '1' ? true : false"
                :id='field.alias' :hint='field.hint'
                :dataValue='this.values[field.alias]'
                :disabled="field.edit_conditions == '0' || field.verification == '1' ? true : false"
                :verification="field.verification == '1' && field.is_verification == '1' ? true : false"
                :unvisibleDataValue="this.unvisibleValue['unvisible_' + field.alias]"
                :unvisible="this.unvisible"  
                :vMark="this.verifMark"
                @updateParentState="this.setValue"
            />
            <File v-else-if='field.type_field == "FILE" || field.type_field == "FILE_LINK"'  
                :label='field.name'
                :require="field.obligatory == '1' ? true : false"
                :arr='true'
                :border='field.alias == "avatar" ? true : false'
                :single='field.alias == "avatar" ? true : false'
                :dataValue='this.values[field.alias]' 
                :id='field.alias' 
                :disabled="field.edit_conditions == '0' || field.verification == '1' ? true : false"
                :verification="field.verification == '1' && field.is_verification == '1' ? true : false"
                :unvisibleDataValue="this.unvisibleValue['unvisible_' + field.alias]"
                :unvisible="this.unvisible"  
                :vMark="this.verifMark"
                @updateParentState="this.setValue"
                :hint='field.hint'/>  
            <Select v-else-if='field.type_field == "SELECT"'
                :label='field.name' 
                :require="field.obligatory == '1' ? true : false"
                :id='field.alias' :hint='field.hint'
                :keywords='this.getKeywords(field)'
                :dataValue='this.values[field.alias]'
                :disabled="field.edit_conditions == '0' || field.verification == '1' ? true : false"
                :verification="field.verification == '1' && field.is_verification == '1' ? true : false"
                :unvisibleDataValue="this.unvisibleValue['unvisible_' + field.alias]"
                :unvisible="this.unvisible"  
                :vMark="this.verifMark"
                :doublebus="this.doublebus"
                @blurInput="(arg) => {
                    this.blureInput(arg, key)
                }"
                @updateParentState="this.setValue"
            />
            <InputDate v-else-if='field.type_field == "DATE"'
                :id='field.alias' 
                :require="field.obligatory == '1' ? true : false"
                :label='field.name' :hint='field.hint'
                :dataValue='this.prepareDateValue(this.values[field.alias])'
                :disabled="field.edit_conditions == '0' || field.verification == '1' ? true : false"
                :verification="field.verification == '1' && field.is_verification == '1' ? true : false"
                :unvisibleDataValue="this.unvisibleValue['unvisible_' + field.alias]"
                :unvisible="this.unvisible"  
                :vMark="this.verifMark"
                :expFormat="true"
                @updateParentState="this.setDateValue"
            />
            <InputDate v-else-if='field.type_field == "TIME"'
                :id='field.alias'
                :require="field.obligatory == '1' ? true : false"
                :label='field.name' :hint='field.hint'
                :dataValue='this.values[field.alias]'
                type='time'
                :disabled="field.edit_conditions == '0' || field.verification == '1' ? true : false"
                :verification="field.verification == '1' && field.is_verification == '1' ? true : false"
                @updateParentState="this.setValue"
                :unvisibleDataValue="this.unvisibleValue['unvisible_' + field.alias]"
                :unvisible="this.unvisible"  
                :vMark="this.verifMark"
            />
            <Address v-else-if='field.type_field == "ADDRESS"'
                :id='field.alias'
                :require="field.obligatory == '1' ? true : false"
                :label='field.name' :hint='field.hint'
                :dataValue='this.values[field.alias]'
                :field="field"
                :addr="this.addr"
                :disabled="field.edit_conditions == '0' || field.verification == '1' ? true : false"
                @updateParentState="this.setValue"  
                :verification="field.verification == '1' && field.is_verification == '1' ? true : false"
                :unvisibleDataValue="this.unvisibleValue['unvisible_' + field.alias]"
                :unvisible="this.unvisible"
                :vMark="this.verifMark"
            /> 
            <Input v-else
                type='text' :label='field.name' 
                :require="field.obligatory == '1' ? true : false"
                :id='field.alias' :hint='field.hint'
                :dataValue='this.values[field.alias]'
                :disabled="field.edit_conditions == '0' || field.verification == '1' ? true : false"
                :verification="field.verification == '1' ? true : false"
                :unvisibleDataValue="this.unvisibleValue['unvisible_' + field.alias]"
                :unvisible="this.unvisible"
                :vMark="this.verifMark"
                @blurInput="(arg) => {
                    this.blureInput(arg, key)
                }"
                :invalid="field.invalid" 
                :invalidHint="field.invalidHint"
                @updateParentState="this.setValue"
            />
            </div>
            <div v-if="field.visible_conditions == '1'">
            <!-- <Input v-if='field.type_field == "PHONE" || field.type_field == "STRING" || field.type_field == "INT"
            || field.type_field == "DECIMAL" || field.type_field =="MONTH_YEAR"
            || field.type_field =="PHONE" || field.type_field == "AMOUNT" || field.type_field == "BIC" || field.type_field == "CARD"'
                type='text' :label='field.rus_name' 
                :id='field.num_field' :hint='field.hint'
                :dataValue='this.values[field.num_field]'
                @updateParentState="this.setValue"
            /> -->
            <Input v-if='field.type_field != "BOOL" && field.type_field != "DATE" && field.type_field != "TIME"
                && field.list != "1"'
                type='text' :label='field.name || field.rus_name' 
                :require="field.obligatory == '1' ? true : false"
                :id='field.num_field' :hint='field.hint'
                :dataValue='this.values[field.num_field]'
                @updateParentState="this.setValue"
                @blurInput="(arg) => {
                    this.blureInput(arg, key)
                }"
                :invalid="field.invalid" 
                :invalidHint="field.invalidHint"
            />
            <Switch v-if='field.type_field == "BOOL"'
                :label='field.name || field.rus_name' 
                :require="field.obligatory == '1' ? true : false"
                :id='field.num_field' :hint='field.hint'
                :dataValue='this.values[field.num_field]'
                @updateParentState="this.setValue"
            />
            <Select v-if='field.list == "1"'
                :label='field.name || field.rus_name' 
                :require="field.obligatory == '1' ? true : false"
                :id='field.num_field' :hint='field.hint'
                :keywords='this.getKeywords(field)'
                :dataValue='this.values[field.num_field]'
                :doublebus="this.doublebus"
                @updateParentState="this.setValue"
                @blurInput="(arg) => {
                    this.blureInput(arg, key)
                }"
            />
            <InputDate v-if='field.type_field == "DATE"'
                :id='field.num_field'
                :require="field.obligatory == '1' ? true : false"
                :label='field.name || field.rus_name' :hint='field.hint'
                :dataValue='this.values[field.num_field]'
                @updateParentState="this.setDateValue"
                :expFormat="true"
            />
            <InputDate v-if='field.type_field == "TIME"'
                :id='field.num_field'
                :require="field.obligatory == '1' ? true : false"
                :label='field.name || field.rus_name' :hint='field.hint'
                :dataValue='this.values[field.num_field]'
                type='time'
                @updateParentState="this.setValue"
            />
            </div>
        </div>
    </div>
</template>

<script>
import Input from '@/components/service/Input';
import Switch from '@/components/service/Switch';
import Select from '@/components/service/Select';
import InputDate from '@/components/service/InputDate';
import File from '@/components/service/File';
import Address from '@/components/service/Address';

export default {
    components: {
        Input,
        Switch,
        Select,
        InputDate,
        File,
        Address
    },
    data() {
        return {
            values: this.getValues(this.dataValues),
            unvisibleValue: this.unvisibleDataValues ? this.getValues(this.unvisibleDataValues) : false,
        }
    },
    props: {
        fields: [Boolean, Object],
        dataValues: [Boolean, Object],
        unvisibleDataValues: [Boolean, Object],
        unvisible: [Boolean],
        verifMark: [Boolean],
        onAlias: [Boolean],
        addr: [Boolean, Object],
        doublebus: [Boolean]
    },
    methods: {
        setValue(arg) {
            this.$emit('updateParentState', {
                id: arg.id,
                unvisible: arg.unvisible,
                value: arg.value
            })
        },     
        setDateValue(arg) {
            let d = arg.value;
            let v = [
                this.padTo2(d.getDate()),
                this.padTo2(d.getMonth() + 1),
                d.getFullYear()
            ].join('.');
            this.$emit('updateParentState', {
                id: arg.id,
                unvisible: arg.unvisible,
                value: v
            })
        },
        prepareDateValue(arg) {
            if (!arg) {
                return '';
            }
            let a = arg.split('.');
            let v = [a[2], a[1], a[0]].join('-');
            return v;
        },  
        padTo2(v) {
            return v.toString().padStart(2, '0');
        },
        checkReg(arg, key) {
            this.fields[key].invalid = false;
            this.fields[key].invalidHint = ''; 
            if (arg.value != '') {
                if (this.fields[key].custom_size != '0' && (arg.value.length < this.fields[key].size_min || arg.value.length > this.fields[key].size_max)) {
                    this.fields[key].invalid = true;
                    this.fields[key].invalidHint = `${this.$t('components.service.GetInputs.1')} ${this.fields[key].size_min} ${this.$t('components.service.GetInputs.2')} ${this.fields[key].size_max} ${this.$t('components.service.GetInputs.3')}`;
                    return;
                }

                if (this.fields[key].re || this.fields[key].custom_regexp) {
                    let re = new RegExp(this.fields[key].re || this.fields[key].custom_regexp);
                    // console.log(re)
                    if (re.test(arg.value) == false) {
                        this.fields[key].invalid = true;
                        this.fields[key].invalidHint = `${this.$t('components.service.GetInputs.4')}`;
                    }
                }
            }

        },
        blureInput(arg, key) {
            this.checkReg(arg,key);
            this.$emit('blureInput', {
                id: arg.id,
                value: arg.value,
                key: key,
            })
        },
        setFileValue(arg) {

        },
        getValues(obj) {
            let res = {}
            for (let key in obj) {
                res[key] = obj[key] ? obj[key] : '';
            }
            return res
        },
        getKeywords(field) {
            if (field.lists) {
                return field.lists;
            } else {
                let codes = field.type_num_val.split('|');
                let vals = field.type_text_val.split('|');
                let arr = [];
                for (let i = 0; i < codes.length; i++) {
                    arr.push({'code': codes[i], 'value': vals[i]})
                }
                return arr;
            }
        },
    },
    mounted() {
        // console.log(this.fields) 
    },

}
</script>

<style lang="scss" scoped>
    .tst {
        .container-fluid:first-child {
            div {
                margin-top: 0 !important;
            }
        }
    }
</style>