<template>
    <div class="d-flex flex-column flex-sm-row mt-3 p-0 mr-0  g-3 align-items-center input" :class="{'mt-0': this.first}">
        <label :for="id" class="col-form-label mt-0 pl-0 pt-0 col-12 col-sm-3 pb-0 pr-1 input-label">{{this.label}}<span class='require' v-if="this.require"> *</span></label>
        <div class="col-12 col-sm-9 input-wrapper mt-0 p-0">
            <InputSimple :id="id" 
            :invalid='this.invalid' 
            :invalidHint='this.invalidHint' 
            :autocomplete="this.autocomplete" 
            :type="this.type" :disabled='this.disabled'
            :success='this.success'
             @blurInput="this.blure" 
             @onEnter="this.onEnter"
             :regCheck='this.regCheck' 
             :dataValue='this.dataValue' 
             @updateParentState="this.setValue"
             :autofocus="this.autofocus"/>
            <Popper v-if="this.hint" class="bs-tooltip" :content='this.hint'/>
            <div v-else-if="this.unvisible" class="mw34"></div>
            <Popper v-if="this.verification" :custom="'item-i check'" :content="$t('views.InfoReg.3')"/>
            <div v-else-if="this.unvisible || this.vMark" class="mw34"></div>
            <SlotPopper v-if="this.unvisible"  :content="$t('views.InfoReg.4')"> 
                <SwitchSimple :id="id" :dataValue='this.unvisibleValue' :type="'sm'" class="ml-1" @updateParentState="this.setUnvisibleValue"/>
            </SlotPopper>
        </div>
    </div>
</template>

<script>
// import { Tooltip } from 'bootstrap';
import InputSimple from '@/components/service/InputSimple';
import SwitchSimple from '@/components/service/SwitchSimple';
import Popper from '@/components/service/Popper';
import SlotPopper from '@/components/service/SlotPopper';

export default {
    components: {
        Popper,
        InputSimple,
        SwitchSimple,
        SlotPopper
    },
    data() {
        return {
            value: this.dataValue ? this.dataValue : '',
            unvisibleValue: this.unvisibleDataValue ? this.unvisibleDataValue : '',
            sizeW: this.$store.state.resize.w,
        }
    },
    props: {
        id: [String, Boolean],
        first: {
            type: Boolean,
            default: false,
        },
        label: [String],
        type: [String],
        hint: [String, Boolean],
        dataValue: [String, Boolean], 
        require: [Boolean],
        disabled: [String, Boolean], 
        regCheck: [Object, Boolean],
        invalid: [Boolean, String], 
        invalidHint: [String, Boolean],
        verification: [String, Boolean],
        autofocus: [Boolean],
        success: [Boolean], 
        autocomplete: {
            type: [String, Boolean],
            default: false,
        },
        unvisible: [Boolean],
        unvisibleDataValue: [String, Boolean], 
        vMark: [Boolean],
    },
    mounted() {
        // if (this.unvisible) {
            // this.bp();
        // }
    },
    methods: {
        setValue(arg) {
            this.$emit('updateParentState', {
                id: arg.id,
                value: arg.value
            })
        },
        // bp() {
        //     new Tooltip(this.$refs.btntooltip2)
        // },
        setUnvisibleValue(arg) {
            this.$emit('updateParentState', {
                id: arg.id,
                unvisible: true,
                value: arg.value
            })
        },
        blure(arg) {
            this.$emit('blurInput', {
                id: arg.id,
                value: arg.value
            })
        },
        onEnter() {
            this.$emit('onEnter');
        },
        bp() {
            new Tooltip(this.$refs.btntooltip2)
        }
    },

}
</script>

<style lang="scss" scoped>
.input {
    font-family: 'HelveticaNeue roman';
    &-wrapper {
        position: relative;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-items: center;
        justify-content: space-between;
    }
}
.mt-1 {
    margin: 0 !important;
}
.col-form-label {
    line-height: 1.2 !important;
}

.require {
    color: red;
}

</style>