<template>
    <div>
        <div class='d-flex justify-content-between neue mb-3'>
            <h4>{{ $t('components.service.modal.PayHref.1') }} {{ this.content.name }}</h4>
            <i class='big item-i x_red pointer' v-on:click='this.close'></i>
        </div>
        <div>
            <h5 class="fs_10">{{ $t('components.service.modal.PayHref.17') }}: <span class='href'>{{ this.doc }}</span></h5>
        </div>
        <div class="mb-3">
            <Select :label="$t('components.service.modal.WidgetData.3')" :require="true"  id='keyt' :keyt='true'  :first='true' :keywords.sync='this.keywords' @updateParentState="this.updateState" />
            <Select :label="$t('views.Payin.2')"  id='on_payform' :hotUpdate="true" :require="true" :dataValue='this.data.on_payform' :keywords.sync='this.pay_sys_keyword' @updateParentState="this.updateState"/>
            <div v-if="this.data.on_payform">
                <Input type='text' :dataValue='this.data.on_exit_transact' :label="$t('components.service.modal.PayHref.2')" :require="true" id='on_exit_transact' @updateParentState="this.updateState"/>
                <Input type='text' :dataValue='this.data.on_sum' :label="$t('components.service.modal.PayHref.3')" :require="true" id='on_sum' @updateParentState="this.updateState"/>
                <Switch :label="$t('components.service.modal.PayHref.12')" :dataValue='this.data.on_identified' id='on_identified' @updateParentState="this.updateState"/>
                <Input type='textarea' :dataValue='this.data.on_comment' :label="$t('components.service.modal.PayHref.4')" id='on_comment' @updateParentState="this.updateState"/>
                <Input type='text' :dataValue='this.data.on_free_param' :label="$t('components.service.modal.PayHref.5')" id='on_free_param' @updateParentState="this.updateState"/>
                <Input type='text' :dataValue='this.data.on_url_success' :label="$t('components.service.modal.PayHref.6')" id='on_url_success' @updateParentState="this.updateState" :hint="$t('components.service.modal.PayHref.9')"/>
                <Input type='text' :dataValue='this.data.on_url_decline' :label="$t('components.service.modal.PayHref.7')" id='on_url_decline' @updateParentState="this.updateState" :hint="$t('components.service.modal.PayHref.10')"/>
                <Input type='text' :dataValue='this.data.on_url_callback' :label="$t('components.service.modal.PayHref.8')" id='on_url_callback' @updateParentState="this.updateState" :hint="$t('components.service.modal.PayHref.11')"/>
            </div>
        </div>
        <div  v-if="this.payLink">
            <h5>{{ $t('components.service.modal.PayHref.15') }}</h5>
            <div class="fs_08 color-acent mb-3">
                <code
                style="word-wrap: break-word;" >
                    {{ this.payLink }}
                </code>
            </div>
            <div class="d-flex mx-auto justify-content-center mb-3">
                <Button :name="$t('components.service.modal.PayHref.13')" type='btn-outline-success' class='mr-1'  v-on:click="this.copyLink"/>
                <Button :name="$t('components.service.modal.PayHref.14')" type='btn-outline-success' v-on:click="this.redir"/>
            </div>
            <h5>{{ $t('components.service.modal.PayHref.16') }}</h5>
            <pre class="d-flex flex-row justify-content-center fs_09 color-acent"
                style="word-wrap: break-word;">
                <code>
                    {{ this.payForm }}
                </code>
            </pre>
        </div>
        
        <div class='d-flex mx-auto justify-content-center pt-3'>
            <Button :name="$t('components.service.modal.WidgetData.2')" class='mr-1' type='btn-outline-warning' 
            v-on:click='() => {
                this.close();
            }'/>
            <Button :name="$t('components.service.modal.WidgetData.4')" type='btn-warning' :disabled="!this.data.on_payform" v-on:click="this.createCode"/>
        </div>
    </div>
</template>

<script>
import Button from '@/components/service/Button'; 
import Select from '@/components/service/Select';
import Input from '@/components/service/Input';
import Switch from '@/components/service/Switch';

export default {
    components: {
        Button,
        Select,
        Input,
        Switch
    },
    props: {
        close: [Function],
    }, 
    data () {
        return { 
            content: this.$store.state.modal.content,
            doc: this.$store.state.modal.url,
            keywords: [],
            pay_sys_keyword: [],
            data: {
                keyt: '',
                on_payform: '',
                on_exit_transact: 'automatic!',
                on_sum: '',
                on_comment: '',
                on_free_param: '',
                on_url_success: '',
                on_url_decline: '',
                on_url_callback: '',
            },
            stringData: '',
            payLink: '',
            payForm: ''
        }
    },
    mounted() {
        console.log(this.content);
    },
    computed: {
        t () {
            let k = this.$store.getters.get_keyts_keywords;
            if (k.length > 0) {
                this.getKeyts(k);
            }
            return k;
        }
    },
    watch: {
        t: {
            handler: function(newData, oldData) {
                // console.log(newData)
                this.getKeyts(newData);
            },
            deep: true,
        }
    },
    methods: {
        getKeyts(k) {
            this.keywords = k;
        },
        updateState(arg) {
            this.data[arg.id] = arg.value;
            if (arg.id == 'keyt') {
                this.getListMerhcant(arg.value);
            }
            if (this.stringData) {
                this.createCode()
            }
        },
        getListMerhcant(arg) {
             this.axios.post('GetListMerchant', {data: {
                'keyt_pay': arg,
            }}).then(res => {
                res.data.body.forEach(e => {
                    this.pay_sys_keyword.push({code: e.form, value: e.name, how: e.how, type:e.type});
                })
            })
        },
        createCode() {
            let link_text = '?ext_transact=' + encodeURIComponent(this.data.on_ext_transact) + 
		    '&num_shop='     + encodeURIComponent(this.content.num    ) + 
		    '&keyt_shop='    + encodeURIComponent(this.data.keyt   ) + 
		    '&identified='   + encodeURIComponent(this.data.on_identified ? '0' : '1'  ) + 
		    '&sum='          + encodeURIComponent(this.data.on_sum         ) + 
		    '&payform='      + encodeURIComponent(this.data.on_payform     ) +
		    '&comment='      + encodeURIComponent(this.data.on_comment     ) + 
		    '&free_param='   + encodeURIComponent(this.data.on_free_param  ) +
		    '&url_success='  + encodeURIComponent(this.data.on_url_success ) +
		    '&url_decline='  + encodeURIComponent(this.data.on_url_decline ) +
		    '&url_callback=' + encodeURIComponent(this.data.on_url_callback);
	
	        // sign
            let sum = '';
	        if ( this.data.on_identified == '1' ) {
		        sum = this.data.on_signed_sum;
            }

	        let signed_str = this.data.on_ext_transact + this.content.num + this.data.on_keyt_shop + this.data.on_identified + sum + this.data.on_payform + this.data.on_comment + this.data.on_free_param + this.data.on_url_success + this.data.on_url_decline + this.data.on_url_callback;
	
            let params = {
                signed_str: signed_str,
                skeys: this.content.skeys + this.content.num
            }

            this.axios.post('MerchantSign', {data: params}).then(res => {
                if (res.data.body.sign && res.data.body.url) {
                    this.payLink = res.data.body.url + link_text + '&sign=' + res.data.body.sign;
                    this.payForm = 
`
<form method="post" action="${res.data.body.url}">
    <input type="hidden" name="ext_transact" value="${this.data.on_ext_transact}">
    <input type="hidden" name="num_shop" value="${this.content.num}">
    <input type="hidden" name="keyt_shop" value="${this.data.on_keyt_shop}">
    <input type="hidden" name="identified" value="${this.data.on_identified}">
    <input type="hidden" name="sum" value="${sum}">
    <input type="hidden" name="payform" value="${this.data.on_payform}">
    <input type="hidden" name="comment" value="${this.data.on_comment}">
    <input type="hidden" name="free_param" value="${this.data.on_free_param}">
    <input type="hidden" name="url_success" value="${this.data.on_url_success}">
    <input type="hidden" name="url_decline" value="${this.data.on_url_decline}">
    <input type="hidden" name="url_callback" value="${this.data.on_url_callback}">
    <input type="hidden" name="sign" value="${res.data.body.sign}">
    <input type="submit" value="Перейти к оплате">
</form>`
                }
            })

        },
        copyLink() {
            let el = document.createElement("textarea");
            document.body.appendChild(el);
            el.value = this.payLink;
            el.select();
            document.execCommand("copy");
            document.body.removeChild(el);
        },
        redir() {
            window.open(this.payLink, '_blank').focus();
        }
        // const dataObject = {
            //     "frame_id": "some_id",
            //     "frame_name": "some_name",
            //     "proc_url": "https://someurl.pay",
            //     "pay_params": {
            //         "comment": "Оплата в MyShop",
            //         "keyt_shop": "00000000000000000000",
            //         "num_shop": "0000",
            //         "summ": "00",
            //     },
            //     "onSuccess": function (result) {
            //         /// Действия при успехе
            //     },
            //     "onFail": function (result) {
            //          /// Дейсвтия при неуспехе
            //     }
            // };

            // <button id='pay_button' onclick='openWidget(dataObject)'>Оплатить</button>
    },
}
</script>

<style lang="scss" scoped>
    
</style>