<template>
    <div ref="modal" tabindex="0" class='vue-modal'>
        <div class='vue-modal-window neue mx-auto content p-4 col-xl-6 col-lg-8 col-10'>
            <Answer v-if="this.$store.state.modal.type == 'answer'" :close="this.close"/>
            <PayStatus v-if="this.$store.state.modal.type == 'pay_status'" :close="this.close"/>
            <PayPass v-if="this.$store.state.modal.type == 'pay_pass'" :close="this.close"/> 
            <Repeat v-if="this.$store.state.modal.type == 'repeat'" :close="this.close"/>
            <Confirm v-if="this.$store.state.modal.type == 'confirm'" :close="this.close"/>
            <HistoryParams v-if="this.$store.state.modal.type == 'history'" :close="this.close"/>
            <MembersModal v-if="this.$store.state.modal.type == 'members'" :close="this.close"/>
            <KeytRename v-if="this.$store.state.modal.type == 'k_rename'" :close="this.close"/>
            <Address v-if="this.$store.state.modal.type == 'address'" :close="this.close"/>
            <SupportList v-if="this.$store.state.modal.type == 'support'" :close="this.close"/>
            <TemplateRename v-if="this.$store.state.modal.type == 'rename'" :close="this.close"/>
            <WidgetData v-if="this.$store.state.modal.type == 'widgetData'" :close="this.close"/>
            <PayHref v-if="this.$store.state.modal.type == 'payHref'" :close="this.close"/>
            <ReEshop v-if="this.$store.state.modal.type == 'changeEshop'" :close="this.close"/>
            <Questionnaire v-if="this.$store.state.modal.type == 'questionnaire'" :close="this.close"/>
        </div>
    </div>
</template>

<script>
import Answer from '@/components/service/modals/Answer';
import Confirm from '@/components/service/modals/Confirm';
import PayPass from '@/components/service/modals/PayPass';
import Repeat from '@/components/service/modals/Repeat';
import KeytRename from '@/components/service/modals/KeytRename';
import HistoryParams from '@/components/service/modals/HistoryParams';
import MembersModal from '@/components/service/modals/MembersModal';
import PayStatus from '@/components/service/modals/PayStatus';
import Address from '@/components/service/modals/Address';
import SupportList from '@/components/service/modals/SupportList';
import TemplateRename from '@/components/service/modals/TemplateRename';
import WidgetData from '@/components/service/modals/WidgetData';
import PayHref from '@/components/service/modals/PayHref';
import ReEshop from '@/components/service/modals/ReEshop';
import Questionnaire from '@/components/service/modals/Questionnaire';


export default {
    components: {
        Answer,
        PayPass,
        Repeat,
        HistoryParams,
        KeytRename,
        Confirm,
        MembersModal,
        PayStatus,
        Address,
        SupportList,
        TemplateRename,
        WidgetData,
        PayHref,
        ReEshop,
        Questionnaire
    },
    mounted() {
        this.$refs.modal.focus();
        document.querySelector('body').style.overflow = 'hidden';
        this.$store.commit('DARK_WRAPPER', true);
    },
    unmounted() {
        document.querySelector('body').style.overflow = 'auto';
    },
    methods: {
        close() {
            this.$store.commit('MODAL', {
                                        action: false,
                                        type: false,
                                        content: false,
                                        }
                                );
            this.$store.commit('DARK_WRAPPER', false);
        }
    },
}
</script>

<style lang="scss">
    .vue-modal {
        position: fixed;
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;
        // background: rgba(0, 0, 0, 0.3); // Make sure this color has an opacity of less than 1
        z-index: 4000;
        padding-top: 20vh;
        outline: none;
        overflow: auto;
        h4 {
            font-size: 1.3rem;
        }
    }
</style>