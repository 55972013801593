export default {
  "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Français"])},
  "views": {
    "Login": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autorisation"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identifiant :"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mot de passe :"])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mot de passe oublié ?"])},
      "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Première visite ?"])},
      "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer un compte"])},
      "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Récupération de mot de passe"])},
      "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Téléphone ou e-mail :"])},
      "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Récupérer"])},
      "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Récupération de mot de passe"])},
      "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom de famille :"])},
      "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prénom :"])},
      "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Patronyme :"])},
      "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date de naissance :"])},
      "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entrer au bureau"])},
      "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retour"])},
      "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continuer"])},
      "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trouver"])},
      "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utilisateur trouvé"])}
    },
    "History": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Période du"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Période au"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exporter"])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtres"])},
      "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réinitialiser"])},
      "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actualiser"])},
      "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montant"])},
      "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transaction"])},
      "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paramètre"])},
      "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type"])},
      "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transaction"])},
      "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date"])},
      "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opération"])},
      "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correspondant"])},
      "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montant"])},
      "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statut"])},
      "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paramètres/Commentaire"])},
      "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucune opération trouvée"])},
      "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montant des opérations"])},
      "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recettes :"])},
      "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" pièces pour un montant de"])},
      "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dépenses :"])},
      "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Types d'opérations"])},
      "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paiements, paiement de services"])},
      "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transferts internes"])},
      "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paiement de facture"])},
      "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transferts entre vos comptes"])},
      "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Création de crypto-chèques"])},
      "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activation de crypto-chèques"])},
      "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Divers"])},
      "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recettes/Dépenses"])},
      "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recettes"])},
      "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dépenses"])},
      "34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["= égal"])},
      "35": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["≠ différent"])},
      "36": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["> supérieur"])},
      "37": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["≥ supérieur ou égal"])},
      "38": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["< inférieur"])},
      "39": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["≤ inférieur ou égal"])},
      "40": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statut"])},
      "41": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terminé avec succès"])},
      "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En cours de traitement"])},
      "43": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erreur"])},
      "44": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aujourd'hui"])},
      "45": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hier"])},
      "46": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Semaine"])},
      "47": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mois"])},
      "48": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Année"])},
      "49": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Historique des opérations"])},
      "50": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rechargement de compte"])},
      "51": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compte non sélectionné"])},
      "52": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Échange sur le marché"])},
      "53": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rechargement d'adresse"])},
      "54": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transferts sur compte propre"])}
    },
    "Home": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passeport numérique"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En savoir plus"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identifiant utilisateur :"])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date d'inscription :"])},
      "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dernière connexion :"])},
      "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IP"])},
      "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mes actifs"])},
      "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comptes"])},
      "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voir tout"])},
      "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recharger"])},
      "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retirer"])},
      "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun compte"])},
      "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mot de passe de confirmation"])},
      "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pour confirmer les transactions, vous devez créer un mot de passe de confirmation."])},
      "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modèles"])},
      "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tous"])},
      "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montant"])},
      "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paiements populaires"])},
      "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actualités"])},
      "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réduire"])},
      "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plus..."])},
      "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun actif"])},
      "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer"])}
    },
    "InfoReg": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informations d'enregistrement"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enregistrer"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmé"])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Public"])}
    },
    "Pay": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modèles de paiement"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trouver"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dans le groupe"])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Groupes de paiements"])},
      "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paiements"])},
      "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montant du paiement"])},
      "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compte de transfert"])},
      "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montant"])},
      "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commentaire"])},
      "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modèle"])},
      "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom du modèle :"])},
      "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enregistrer"])},
      "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enregistrer le modèle"])},
      "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transférer"])}
    },
    "Payin": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compte à recharger"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Méthode de paiement"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom du modèle :"])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enregistrer"])},
      "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enregistrer le modèle"])},
      "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continuer"])},
      "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modèles"])},
      "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rechargement de compte"])},
      "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Détails du transfert"])},
      "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vérifier le transfert"])},
      "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informations sur les transferts"])},
      "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instructions"])},
      "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rechargement d'adresse"])},
      "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse à recharger"])},
      "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exporter"])},
      "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["À la liste des tâches"])},
      "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse de transfert"])}
    },
    "Return": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Répéter l'opération"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Historique des opérations"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liste des comptes"])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erreur inconnue"])},
      "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liste des jetons"])}
    },
    "Transfer": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compte de transfert :"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montant :"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commentaire :"])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Protection de la transaction"])},
      "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code :"])},
      "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Générer"])},
      "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Durée de protection :"])},
      "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["jours"])},
      "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom du modèle :"])},
      "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enregistrer"])},
      "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enregistrer comme modèle"])},
      "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transférer"])},
      "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modèles"])},
      "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transfert à un autre utilisateur"])},
      "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le code de protection est le code que le destinataire doit entrer pour terminer la transaction. Le destinataire verra immédiatement le transfert protégé dans la section \"Historique des opérations\", mais ne pourra utiliser les fonds qu'après avoir saisi le code de protection dans la carte de transaction. Si la période de validité du paiement expire, les fonds sont retournés à l'expéditeur."])},
      "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse de transfert"])}
    },
    "TransferSelf": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vers"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montant"])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transférer"])},
      "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transfert entre vos comptes"])},
      "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transferts sur compte propre"])}
    },
    "Unexpected": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quelque chose s'est mal passé"])}
    },
    "Keyts": {
      "List": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compte"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Solde"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statut"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informations supplémentaires"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actions"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actif"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entrée"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sortie"])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer une nouvelle adresse"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer un nouveau compte"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer sélectionnés"])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mes actifs"])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liste des comptes"])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom du compte"])},
        "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voulez-vous vraiment supprimer les comptes ?"])},
        "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voulez-vous vraiment supprimer le compte"])},
        "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun actif trouvé"])},
        "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun compte trouvé"])},
        "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Renommer l'adresse"])},
        "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voulez-vous vraiment supprimer les adresses ?"])},
        "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voulez-vous vraiment supprimer l'adresse"])}
      },
      "New": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type d'adresse"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type de compte"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Devise :"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom :"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer une adresse"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer un compte"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Création d'une nouvelle adresse"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Création d'un nouveau compte"])}
      }
    },
    "Safety": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sécurité"])},
      "Antiphishing": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le paramétrage antiphishing est un mécanisme de protection supplémentaire lors de l'utilisation du compte."])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La phrase de bienvenue que vous avez définie sera affichée en haut de chaque page du compte."])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La phrase correcte vous indique que vous êtes sur le bon site, une phrase incorrecte signalera : n'effectuez aucune opération et contactez immédiatement le service de sécurité de notre système."])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activer antiphishing :"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phrase antiphishing :"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enregistrer"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Antiphishing"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les modifications prendront effet après la prochaine connexion"])}
      },
      "IP": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le paramétrage d'accès par adresse IP ne vous permettra pas (ou à un intrus) de travailler avec le système à partir d'une adresse qui n'est pas dans la liste des adresses autorisées."])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Restriction d'accès au compte par adresse IP"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter des adresses"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse : "])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bloc d'adresses : "])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["à :"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre IP : "])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liste des adresses"])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["№"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De l'adresse"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["À l'adresse"])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accès"])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actions"])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer"])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucune adresse"])},
        "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enregistrer"])},
        "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Refuser"])},
        "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autoriser"])},
        "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entrez l'adresse IP"])},
        "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse IP incorrecte"])},
        "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucune adresse IP ou bloc d'adresses spécifié."])},
        "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucune adresse IP autorisée pour l'autorisation. Vous ne pourrez pas accéder à votre compte."])},
        "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accès par IP"])},
        "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["de :"])}
      },
      "Magic": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le mot magique est un paramètre de sécurité supplémentaire."])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si le mot magique est activé, le système vous demandera, à chaque connexion, d'entrer certains caractères (selon leur ordre) du mot magique."])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mot magique :"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Demander le mot magique :"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lors de l'accès au compte :"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lors du paiement par le marchand :"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lors de l'utilisation de la carte :"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enregistrer"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mot magique"])}
      },
      "Onekey": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les clés à usage unique augmentent considérablement la sécurité du système."])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lors de la connexion au programme, on vous demandera non seulement de saisir l'identifiant et le mot de passe, mais aussi la clé à usage unique suivante. L'activation est effectuée successivement. La dernière clé est valide jusqu'à ce que vous créiez une nouvelle série de clés."])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utiliser des clés à usage unique"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liste des clés"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Générer"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exporter"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["№"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clé"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clés à usage unique"])}
      },
      "Password": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ancien mot de passe :"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nouveau mot de passe :"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmation du mot de passe :"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enregistrer"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["\"Nouveau mot de passe\" et \"Confirmation du mot de passe\" ne correspondent pas."])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Changer le mot de passe"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chaîne de 4 à 63 caractères. Les caractères autorisés sont les lettres de l'alphabet latin, les chiffres et les symboles"])}
      },
      "PayPassword": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ancien mot de passe :"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nouveau mot de passe :"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmation du mot de passe :"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nouveau mot de passe :"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmation du mot de passe :"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enregistrer"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enregistrer"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["\"Nouveau mot de passe\" et \"Confirmation du mot de passe\" ne correspondent pas."])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le mot de passe ne doit pas contenir de caractères cyrilliques"])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer un mot de passe de confirmation"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifier le mot de passe de confirmation"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["À la page principale"])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chaîne de 4 à 65 caractères. Les caractères autorisés sont les lettres de l'alphabet latin, les chiffres et les symboles"])}
      },
      "SecretQuestion": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si vous oubliez le mot de passe pour accéder au compte, vous pourrez le récupérer en répondant à la question secrète."])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dans cette section, vous pouvez changer la question/réponse secrète."])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Question secrète"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre question :"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réponse :"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enregistrer"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Question secrète"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Question personnelle"])}
      }
    },
    "Stock": {
      "CurrTransfer": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Je remets"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["solde "])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Je reçois"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paire de devises :"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comptes :"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type d'échange :"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transfert au marché - l'échange se fera au taux le plus avantageux au moment de l'exécution de l'ordre. Attention, le montant à recevoir peut changer et différer du montant calculé en fonction des ordres au moment de l'échange."])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transfert au prix fixe se fait conformément au montant et au taux que vous avez spécifiés. Une commande sera créée. Si le taux spécifié diffère fortement du taux du marché, la fermeture de la commande peut prendre beaucoup de temps."])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taux (prévision) :"])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taux"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taux non défini,"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["il est recommandé de définir le taux soi-même"])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montant :"])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utiliser tout"])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commission :"])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commission :"])},
        "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total :"])},
        "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selon le marché"])},
        "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(échange rapide à la meilleure offre)"])},
        "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taux fixe"])},
        "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(placer une commande au taux spécifié)"])},
        "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun compte approprié"])},
        "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taux"])},
        "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Changement sur 24 heures"])},
        "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fluctuations sur 24 heures"])},
        "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Volume des transactions sur 24 heures"])},
        "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le montant ne peut pas être nul !"])},
        "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Échanger"])},
        "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pour voir les données du résumé, sélectionnez une paire de devises"])}
      },
      "StockMarket": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bourse"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marché. Résumé"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offres similaires"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offres contraires"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taux"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ils donnent"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ils reçoivent"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mes commandes"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dernières transactions"])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taux"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Donné"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date et heure"])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date"])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Je remets"])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Je reçois"])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statut"])},
        "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taux"])},
        "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opérations"])},
        "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["commission"])},
        "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annuler"])},
        "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Répéter"])},
        "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucune offre"])},
        "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucune transaction"])},
        "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucune commande"])},
        "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Complètement exécuté"])},
        "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Traitement non démarré"])},
        "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Traitement démarré"])},
        "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Partiellement exécuté"])},
        "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Complètement annulé"])},
        "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Partiellement exécuté et annulé"])},
        "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reçu"])},
        "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toutes les commandes"])}
      },
      "OrderList": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toutes les commandes"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réinitialiser"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtres"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actualiser"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Je remets"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Je reçois"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statut"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taux"])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opérations"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["commission"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annuler"])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Répéter"])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucune commande"])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montant minimum à donner"])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montant maximum à donner"])},
        "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montant minimum à recevoir"])},
        "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montant maximum à recevoir"])},
        "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre de tentatives de transaction"])},
        "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activité"])},
        "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Devise de paiement"])},
        "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Devise à recevoir"])},
        "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compte débiteur"])},
        "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compte créditeur"])},
        "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liste des sous-marchands"])},
        "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compte non sélectionné"])},
        "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Période du"])},
        "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Période au"])},
        "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aujourd'hui"])},
        "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hier"])},
        "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Semaine"])},
        "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mois"])},
        "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Année"])},
        "34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type de date non sélectionné"])},
        "35": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Création"])},
        "36": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fin"])},
        "37": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fin"])},
        "38": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Début"])},
        "39": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quelle partie est fixe et quelle partie est variable"])},
        "40": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le montant à transférer est fixe"])},
        "41": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montant à recevoir est fixé"])},
        "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type de commande"])},
        "43": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'utilisateur souhaite échanger rapidement à la meilleure offre"])},
        "44": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Échange à un taux fixe"])},
        "45": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statut"])},
        "46": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Traitement non démarré"])},
        "47": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Traitement démarré"])},
        "48": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Partiellement exécuté"])},
        "49": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Complètement exécuté"])},
        "50": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Complètement annulé"])},
        "51": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Partiellement exécuté et annulé"])},
        "52": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Devise à donner"])},
        "53": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Devise à recevoir"])}
      },
      "Offers": {
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montant à transférer"])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montant"])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montant à recevoir"])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtres"])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actualiser"])},
        "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["transactions"])},
        "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transaction"])},
        "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucune offre"])},
        "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offres P2P"])},
        "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID du vendeur"])},
        "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code de l'offre"])},
        "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Devise"])},
        "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])},
        "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vendeur vérifié"])},
        "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réservé aux utilisateurs vérifiés"])},
        "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impossible de faire une transaction avec soi-même"])},
        "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionner une devise"])}
      }
    },
    "VA": {
      "ListVa": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["№"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logo"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TICKER"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Catégorie"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Objectif"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actions"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liste des crypto-actifs"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun actif"])}
      },
      "NewVa": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom :"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le nom est le nom complet du crypto-actif tel qu'il sera vu par tout le monde : l'émetteur lui-même, les acheteurs, les vendeurs, le processeur et d'autres. La chaîne ne doit pas comporter plus de 40 caractères."])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TICKER"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un ticker est un nom court pour un crypto-actif, une abréviation abrégée entre 3 et 10 caractères de long."])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Catégorie :"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionnez la catégorie à laquelle votre crypto-actif correspond le mieux"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formats pris en charge : png, jpg, jpeg. Taille du fichier pas plus de 1 Mo, 500*500 pixels"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Décrire la raison pour laquelle ce crypto-actif est émis par l'émetteur, dans quel domaine il travaille ou travaillera, quelle est sa mission."])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logo :"])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Objectif / mission / but :"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Précision :"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Émission :"])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valeur unitaire :"])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre de décimales pour le montant. Par exemple, la précision de la plupart des monnaies fiduciaires telles que le dollar américain ou l'euro est de 2."])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le nombre d'unités de l'actif cryptographique en cours d'émission. Le montant de l'émission sera immédiatement disponible sur votre compte une fois la transaction de création de l'actif cryptographique terminée."])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valeur unitaire déclarée :"])},
        "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Garantie :"])},
        "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compte :"])},
        "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personne responsable :"])},
        "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Décision d'émettre un crypto-actif:"])},
        "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exporter les opérations vers la blockchain :"])},
        "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type de blockchain :"])},
        "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enable crypto-asset transactions:"])},
        "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Restrictions/Sanctions :"])},
        "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spécifiez quels citoyens ou résidents fiscaux de quels pays de la liste ci-dessus sont interdits d'utiliser des crypto-actifs dans leur chiffre d'affaires:"])},
        "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autres restrictions et conditions :"])},
        "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse d'hébergement:"])},
        "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse du livre blanc :"])},
        "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email :"])},
        "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telegram :"])},
        "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Twitter :"])},
        "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Facebook :"])},
        "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autres contacts :"])},
        "34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retour"])},
        "35": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enregistrer"])},
        "36": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suivant"])},
        "37": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer un crypto-actif"])},
        "38": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom et objectif"])},
        "39": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Caractéristiques financières"])},
        "40": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Émetteur"])},
        "41": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Export vers la blockchain"])},
        "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Limites et restrictions"])},
        "43": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contacts"])},
        "44": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non défini"])},
        "45": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fixation au pair avec des devises fiduciaires et d'autres actifs"])},
        "46": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Émetteur (Personne émettant un crypto-actif)"])},
        "47": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offrant (Personne distribuant un crypto-actif)"])},
        "48": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Expéditeur"])},
        "49": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Destinataire"])},
        "50": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Moi (activer le parrainage)"])},
        "51": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Placement de crypto-actifs"])},
        "52": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crypto-actif désigne une représentation numérique d'une valeur ou d'un droit qui peut être transférée et stockée électroniquement à l'aide de la technologie blockchain. Les crypto-actifs ne comprennent PAS les monnaies fiduciaires, les titres et autres instruments financiers et actifs soumis à une réglementation gouvernementale distincte."])}
      }
    },
    "Notify": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paramètres de notification"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activer"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notifier des opérations"])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autorisation (connexion au compte personnel, application mobile, etc.)"])},
      "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notifier en cas d'événement « Réalisation d'opérations financières avec la carte »"])},
      "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dépenses (tout paiement, transfert, paiement de services, etc.)"])},
      "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entrée de fonds"])},
      "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réception d'un message via le courrier interne"])},
      "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réception d'une facture de paiement"])},
      "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modification des paramètres de sécurité"])},
      "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmer les opérations avec le code du message"])},
      "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmer l'autorisation avec le code du message"])},
      "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmer lors de la « Réalisation d'opérations financières avec la carte »"])},
      "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmer la modification des paramètres de sécurité avec le code du message"])},
      "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmer les dépenses avec le code du message"])},
      "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifications non enregistrées, enregistrer ?"])},
      "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enregistrer"])},
      "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ne pas enregistrer"])},
      "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enregistrer"])}
    },
    "Masspayment": {
      "List": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date de réception"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date de traitement"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statut"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre de paiements"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["y compris réussis"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["y compris échoués"])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paiements de masse"])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transaction"])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Résultat"])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fournisseur de services"])},
        "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montant"])},
        "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commission"])},
        "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commentaire"])},
        "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1er paramètre"])},
        "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun paiement trouvé"])}
      },
      "New": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous pouvez effectuer plusieurs paiements en même temps. Pour cela, vous devez préalablement créer un fichier avec la description des paiements au format \"XLS\", \"XLSX\", \"CSV\" ou \"XML\"."])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analyse du fichier"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correspondant"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montant"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commentaire"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paramètre"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informations sur le fichier"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre total de paiements : "])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montant total : "])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fichier de paiement"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compte de débit"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Effectuer les paiements"])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continuer"])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Téléchargement du fichier de paiement"])},
        "57": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paiements et transferts de masse"])}
      },
      "Info": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le service de paiements de masse permet de transférer rapidement des fonds à d'autres utilisateurs et d'effectuer des opérations de paiement aux fournisseurs de services."])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dans la section"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Télécharger le fichier de paiement"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["vous pouvez télécharger un fichier au format Excel (XLS, XLSX, CSV) ou XML."])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liste des tâches"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["vous permet de voir les statuts des demandes de paiements de masse créées précédemment."])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous pouvez créer un fichier dans un éditeur de tableur comme Excel."])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Télécharger un fichier exemple"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remarques importantes :"])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Format de fichier - CSV, XLS ou XLSX."])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le fichier ne doit pas contenir de titres."])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pour que les grands nombres ne se transforment pas en format exponentiel (par exemple, \"5,46546E+15\") et que les zéros initiaux ne soient pas coupés, vous pouvez ajouter un apostrophe (') avant le nombre, ou définir le format de la cellule comme texte avant de saisir les données."])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Format des colonnes pour les paiements aux fournisseurs de services"])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liste des colonnes dans le fichier :"])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code de l'opérateur"])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". Spécifiez-le à partir de la liste des opérateurs"])},
        "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ici"])},
        "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". (Pour tous les opérateurs mobiles, vous pouvez spécifier le code opérateur 7000 - dans ce cas, l'opérateur sera automatiquement déterminé par le numéro de téléphone.)"])},
        "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montant"])},
        "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". Le montant est spécifié dans la devise du compte à partir duquel le paiement sera effectué. Si la devise du compte bénéficiaire est différente de la devise du compte choisi pour le paiement, le taux de change du système sera automatiquement appliqué à la transaction."])},
        "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commentaire de paiement"])},
        "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". Optionnel, peut être laissé vide ; longueur maximale de 255 caractères."])},
        "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paramètre de paiement 1"])},
        "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". Saisissez la valeur du paramètre requis par l'opérateur, par exemple le numéro de téléphone. De nombreux opérateurs se contentent d'un seul paramètre de paiement, mais si le nombre de paramètres est supérieur à un, vous devez les spécifier tous dans les colonnes adjacentes à la première."])},
        "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paramètre de paiement 2"])},
        "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paramètre de paiement N"])},
        "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous pouvez consulter les paramètres de paiement en accédant au paiement d'un opérateur spécifique :"])},
        "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Format des colonnes pour les transferts de masse aux utilisateurs"])},
        "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code de l'opérateur"])},
        "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". Spécifiez 0 ou laissez-le vide."])},
        "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montant"])},
        "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". Le montant est spécifié dans la devise du compte à partir duquel le paiement sera effectué. Si la devise du compte bénéficiaire est différente de la devise du compte choisi pour le paiement, le taux de change du système sera automatiquement appliqué à la transaction."])},
        "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commentaire de paiement"])},
        "34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". Optionnel, peut être laissé vide ; longueur maximale de 255 caractères."])},
        "35": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compte bénéficiaire"])},
        "36": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". Saisissez ici le numéro de compte de l'utilisateur auquel le transfert doit être effectué (20 chiffres)."])},
        "37": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Format des colonnes pour la distribution de prêts par un agent de crédit"])},
        "38": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". Cette ligne indique que les paiements sont des prêts."])},
        "39": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montant"])},
        "40": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". Le montant est spécifié dans la devise du produit de crédit."])},
        "41": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commentaire sur le crédit"])},
        "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". Optionnel, peut être laissé vide ; longueur maximale de 255 caractères."])},
        "43": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID de l'emprunteur"])},
        "44": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". Veuillez noter que l'emprunteur doit avoir un passeport numérique Mfo-BorrowPerson."])},
        "45": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code du produit de crédit"])},
        "46": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". Vous pouvez le voir dans la liste des produits de crédit lors de la distribution manuelle du prêt."])},
        "47": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Période"])},
        "48": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". Simplement le nombre de périodes (les périodes utilisées sont définies dans le produit de crédit, par exemple, elles peuvent être des jours, des semaines, des mois, etc.)"])},
        "49": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code de l'opérateur (numéro du formulaire)"])},
        "50": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". Le remplissage de ce champ et des suivants est optionnel. Si vous ne les remplissez pas, l'emprunteur pourra retirer le prêt plus tard de manière autonome via son compte personnel."])},
        "51": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annuler en cas d'erreur"])},
        "52": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". Annuler (1) le prêt accordé si la transaction de retrait échoue, ou le laisser pour un retrait autonome ultérieur (0)."])},
        "53": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paramètre de paiement 1"])},
        "54": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". Saisissez la valeur du paramètre requis par l'opérateur, par exemple le numéro de téléphone. De nombreux opérateurs se contentent d'un seul paramètre de paiement, mais si le nombre de paramètres est supérieur à un, vous devez les spécifier tous dans les colonnes adjacentes à la première."])},
        "55": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paramètre de paiement 2"])},
        "56": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paramètre de paiement N"])}
      }
    },
    "Support": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statut"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sujet"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dernier message"])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Catégorie"])},
      "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actions"])},
      "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucune demande"])},
      "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Catégorie"])},
      "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Priorité"])},
      "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sujet"])},
      "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Texte du message"])},
      "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fichiers"])},
      "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous pouvez télécharger un ou plusieurs fichiers. Taille maximale du fichier - 10 Mo."])},
      "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Envoyer"])},
      "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Basse"])},
      "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Normale"])},
      "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Haute"])},
      "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Support technique"])},
      "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Demandes actives"])},
      "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Archive"])},
      "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nouvelle demande"])},
      "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Traitement par l'opérateur"])},
      "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Demande créée"])},
      "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En cours de traitement, attendez la réponse de l'opérateur"])},
      "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Demande fermée"])},
      "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Demande fermée"])},
      "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun nouveau message"])},
      "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nouveau message de l'opérateur"])},
      "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun nouveau message"])},
      "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Envoyer à l'archive"])}
    },
    "Templates": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modèles"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rechargement"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transfert"])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retrait"])},
      "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom du modèle"])},
      "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opérateur"])},
      "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Champs"])},
      "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opérations"])},
      "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pas de modèle de paiement"])},
      "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bénéficiaire"])},
      "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voulez-vous vraiment supprimer le modèle n°"])},
      "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Renommer le modèle"])},
      "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Renommer"])},
      "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer"])},
      "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utiliser"])}
    },
    "CorrInfo": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informations sur le correspondant"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID / Compte / E-mail / Téléphone"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID / Adresse crypto / E-mail / Téléphone"])}
    },
    "Merchant": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informations générales"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informations de paiement"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Signature de contrôle"])},
      "List": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mes magasins"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun magasin trouvé"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer un code de widget"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer un lien de paiement"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifier"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer le magasin"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voulez-vous vraiment supprimer le magasin?"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enregistrer le magasin"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mode de paiement"])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activité"])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accepter"])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL"])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actions"])}
      },
      "New": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enregistrer le magasin"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un magasin électronique enregistré dans le système permet d'accepter des paiements des clients via le système de marchand."])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les magasins électroniques, les bureaux de change et toute autre ressource internet souhaitant accepter des paiements en leur faveur sont enregistrés en tant que magasins."])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom ou marque"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL du magasin"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description courte du magasin"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type de service"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le magasin est actif"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clé secrète"])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autoriser les paiements automatiques"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Envoyer les résultats au script"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse du script"])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retour après un paiement réussi"])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Après un paiement échoué"])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enregistrer"])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Générer"])},
        "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Au moins 30 caractères. Caractères autorisés : lettres latines, chiffres"])},
        "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utilisé pour vérifier l'intégrité et l'authenticité des données transmises par le système de paiement (lorsque le magasin envoie l'utilisateur au marchand) et par le magasin (lors de la réception des résultats du paiement)"])},
        "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suivant"])},
        "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retour"])},
        "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["À la liste des magasins"])}
      },
      "Invoices": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liste des factures"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucune facture trouvée"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statut"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commentaire"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montant"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Magasin"])}
      }
    },
    "P2P": {
      "New": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Devise"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compte"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Devise externe"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Groupe"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom RU"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Synonymes"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pays"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si rempli, l'annonce ne sera visible que pour les utilisateurs des pays spécifiés"])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Devise"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unités"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abréviation"])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Précision"])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Détails"])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Direction de transfert"])},
        "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minimum"])},
        "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maximum"])},
        "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Limite"])},
        "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Temps d'attente (min)"])},
        "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annonce active"])},
        "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disponible uniquement par lien"])},
        "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disponible uniquement pour les utilisateurs vérifiés"])},
        "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Précédent"])},
        "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Placer"])},
        "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suivant"])},
        "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Devise interne"])},
        "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Restrictions"])},
        "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer une annonce"])},
        "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Devise externe personnelle"])},
        "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ne pas associer la devise"])},
        "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oui"])},
        "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réduire le taux"])},
        "34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réinitialiser"])},
        "35": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pas de compte dans cette devise"])},
        "36": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer un compte"])},
        "37": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spécifier le taux"])},
        "38": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le taux est correct"])},
        "39": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réduire le taux"])},
        "40": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réinitialiser"])},
        "41": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minimum supérieur au maximum"])}
      },
      "SingleSwap": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Je transmets"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Je reçois"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commentaire"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Raison"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annuler la transaction"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmer la participation à la transaction"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Refuser la transaction"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prolonger le temps d'attente"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annulation de la transaction"])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statut de l'utilisateur dans la transaction non défini. Quelque chose a mal tourné"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Demander un arbitrage"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accepter l'arbitrage"])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notifier du transfert effectué"])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmer la réception du transfert"])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Évaluer la transaction"])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Envoyer l'évaluation"])},
        "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transaction n°"])},
        "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correspondant"])},
        "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Délai"])},
        "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["aujourd'hui"])},
        "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["demain"])}
      },
      "Offers": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Donner"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montant"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recevoir"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtres"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actualiser"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vendeur"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous donnez"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous recevez"])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Limites"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actions"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["transactions"])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transaction"])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucune transaction"])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offres"])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avantage"])}
      },
      "ActiveSwaps": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code de transaction"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Je transmets"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Je reçois"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Direction"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taux"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statut"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Délai"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actions"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucune transaction active"])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mes transactions"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actif"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Devise interne"])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inactif"])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correspondant"])}
      },
      "Swaps": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activité"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description de la devise"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucune annonce"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mes annonces"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mes annonces"])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nouvelle annonce"])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activité"])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actif"])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inactif"])},
        "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tous"])},
        "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID"])},
        "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Direction"])},
        "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Devise externe"])},
        "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Devise interne"])}
      },
      "RequestP2POffer": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Demande de transaction"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offre"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vendeur"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Délai"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Temps d'attente des actions des parties"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taux"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous donnez"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous recevez"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Demander une transaction"])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer un compte"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compte non sélectionné"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montant"])}
      },
      "CheckCurrP2POffers": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionner"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Donner"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recevoir"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["devise"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Je transmets"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["interne"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["je reçois"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Devises internes"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucune devise trouvée"])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Devises externes"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["externe"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionné :"])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Internes"])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Externes"])}
      },
      "P2PChat": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["hier"])}
      }
    },
    "p2p": {
      "Edit": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annonce"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Détails"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minimum"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maximum"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Limite de montant"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Temps d'attente (min)"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annonce active"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disponible uniquement par lien"])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disponible uniquement pour les utilisateurs vérifiés"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annuler"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enregistrer"])}
      }
    },
    "offers": {
      "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Début"])}
    },
    "MultiSelect": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réinitialiser"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tout sélectionner"])}
    }
  },
  "components": {
    "service": {
      "Registration": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inscription"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compte personnel"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compte professionnel"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom de famille :"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prénom :"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Patronyme :"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les lettres de l'alphabet latin sont acceptées, ainsi que le trait d'union et l'apostrophe."])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bond"])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les lettres de l'alphabet latin sont acceptables, ainsi que le trait d'union, l'espace et l'apostrophe."])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["James"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["None"])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les lettres de l'alphabet latin sont acceptées, ainsi que le trait d'union, les guillemets, les espaces, les chiffres et l'apostrophe."])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["\"Mon entreprise\" SARL"])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Téléphone"])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
        "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mot de passe"])},
        "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lettres latines (majuscules et minuscules), chiffres, symboles _ - . ! $ % ^ * ="])},
        "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Répétez le mot de passe"])},
        "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les mots de passe ne correspondent pas"])},
        "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retour"])},
        "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inscription"])},
        "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suivant"])},
        "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statut"])},
        "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informations utilisateur"])},
        "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mot de passe"])},
        "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indiquez une adresse e-mail ou un numéro de téléphone valide. Le numéro de téléphone doit être au format international, par exemple : +499001234567."])},
        "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous êtes enregistré avec succès"])},
        "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous pouvez maintenant"])},
        "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["vous connecter à votre compte personnel"])},
        "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre compte sera vérifié par notre employé et nous vous informerons des résultats de son activation plus tard"])},
        "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un e-mail avec un lien de confirmation d'inscription a été envoyé à votre adresse e-mail"])},
        "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["À votre adresse e-mail"])},
        "34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Après la première connexion, il vous sera demandé de confirmer votre numéro de téléphone"])},
        "35": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SIREN"])},
        "36": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pays de nationalité"])}
      },
      "Members": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Destinataire :"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utilisateur non trouvé"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionner"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En savoir plus"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélection des utilisateurs"])}
      },
      "modal": {
        "Answer": {
          "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Succès"])},
          "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erreur"])}
        },
        "Confirm": {
          "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmation"])},
          "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annuler"])},
          "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continuer"])}
        },
        "History": {
          "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["transaction"])},
          "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paramètres généraux"])},
          "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Changement des soldes des comptes"])},
          "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["À la fin de l'opération :"])},
          "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Au début de l'opération :"])},
          "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paramètres supplémentaires"])},
          "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opérations dans la blockchain"])},
          "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Callback"])},
          "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prochaine tentative :"])},
          "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Temps"])},
          "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réponse"])},
          "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Protection de la transaction"])},
          "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Durée de protection :"])},
          "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jusqu'à"])},
          "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code de protection :"])},
          "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmer"])},
          "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paramètres du modèle"])},
          "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom du modèle :"])},
          "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enregistrer"])},
          "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annuler l'opération"])},
          "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Répéter l'opération"])},
          "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enregistrer le modèle"])},
          "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fermer"])},
          "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numéro de transaction"])},
          "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opération"])},
          "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correspondant"])},
          "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date de paiement"])},
          "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montant"])},
          "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commission"])},
          "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commentaire"])},
          "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Résultat"])},
          "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numéro du formulaire de paiement"])},
          "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recettes/Dépenses"])},
          "34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Changement des soldes des adresses"])},
          "35": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmer l'accréditation"])},
          "36": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Renvoyer l'argent à l'expéditeur"])},
          "37": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opération suspecte"])},
          "38": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Options d'actions :"])}
        },
        "Rename": {
          "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom :"])},
          "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annuler"])},
          "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continuer"])}
        },
        "MembersModal": {
          "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utilisateur non trouvé"])},
          "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionner"])},
          "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fermer"])},
          "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recherche"])}
        },
        "PayPass": {
          "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mot de passe :"])},
          "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annuler"])},
          "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continuer"])},
          "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saisir le mot de passe de confirmation"])}
        },
        "PayStatus": {
          "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Répéter l'opération"])},
          "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Historique des opérations"])},
          "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liste des comptes"])},
          "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Succès"])},
          "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erreur"])},
          "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erreur inconnue"])},
          "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modèles"])},
          "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mes actifs"])}
        },
        "Repeat": {
          "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entrée :"])},
          "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continuer"])}
        },
        "Address": {
          "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionnez l'adresse"])},
          "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Localité"])},
          "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse"])},
          "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annuler"])},
          "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enregistrer"])},
          "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pays"])},
          "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Région"])},
          "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["District"])},
          "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arrondissement"])},
          "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ville"])},
          "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Territoire urbain"])},
          "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Localité"])},
          "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rue"])},
          "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Élément d'adresse supplémentaire"])},
          "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Précision"])},
          "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maison"])},
          "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code postal"])}
        },
        "SupportList": {
          "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Demande de support technique"])},
          "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["de"])},
          "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chat"])},
          "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter un message"])},
          "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fichiers"])},
          "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous pouvez télécharger un ou plusieurs fichiers. Taille maximale du fichier - 10 Mo."])},
          "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Texte du message"])},
          "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Envoyer"])}
        },
        "Template": {
          "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom"])}
        },
        "WidgetData": {
          "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Création de widget pour"])},
          "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annuler"])},
          "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compte"])},
          "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Générer"])},
          "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paiement en"])},
          "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montant du paiement"])},
          "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actions en cas de succès"])},
          "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actions en cas d'échec"])}
        },
        "PayHref": {
          "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Génération de code / lien de paiement"])},
          "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numéro d'opération du magasin"])},
          "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montant"])},
          "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commentaire"])},
          "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paramètre supplémentaire"])},
          "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL de succès"])},
          "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL d'échec"])},
          "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL de notification"])},
          "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL de retour du client après paiement réussi"])},
          "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL de retour du client après échec"])},
          "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL d'envoi de notification de paiement"])},
          "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fixer le montant"])},
          "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copier"])},
          "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vérifier"])},
          "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lien de paiement"])},
          "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formulaire"])},
          "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Documentation"])},
          "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Durée de vie de la facture en minutes, par défaut 60"])},
          "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Durée de validité du lien"])},
          "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lien"])},
          "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formulaire"])},
          "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Widget"])}
        },
        "ReEshop": {
          "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifier le magasin"])}
        },
        "Questionnaire": {
          "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enregistrer"])},
          "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si vous voyez ceci, le formulaire n'a pas été reçu (mais vous ne devriez pas voir cela)"])}
        },
        "Emission": {
          "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Émission supplémentaire"])},
          "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Devise"])},
          "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compte de couverture"])},
          "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compte de commission"])},
          "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montant de l'émission"])},
          "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commentaire"])},
          "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continuer"])}
        }
      },
      "Comiss": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Débit"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commission"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Info"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crédit"])}
      },
      "File": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionner un fichier"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formats autorisés :"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Format inconnu"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fichier trop volumineux, taille maximale :"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionnez ou faites glisser les fichiers ici pour les télécharger"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionner"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Télécharger"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annuler"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fichiers téléchargés"])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fichiers"])}
      },
      "PayStatusTemplate": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montant :"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Résultat :"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transaction :"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actualiser le statut"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liens utiles"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Blockchain :"])}
      },
      "SafetyRes": {
        "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autres paramètres de sécurité"])}
      },
      "modlas": {
        "Repeat": {
          "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmation requise"])},
          "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'autorisation doit être confirmée par une clé à usage unique"])},
          "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'autorisation doit être confirmée par le mot magique"])},
          "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'inscription doit être confirmée par un code SMS"])}
        }
      },
      "GetInputs": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chaîne de"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["à"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["caractères"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valeur non valide"])}
      },
      "TableList": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recherche"])}
      },
      "topmenu": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crypto ID :"])}
      },
      "pagination": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Affiché :"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["total"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Par page"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afficher"])}
      }
    },
    "Payin": {
      "Table": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmer le paiement"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Calculer la commission de remboursement"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rembourser le paiement"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transaction"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hash dans la blockchain"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Risque AML"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montant"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commission"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["À créditer"])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statut"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si vous acceptez les nouvelles conditions, confirmez le crédit :"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si ces conditions ne vous conviennent pas, vous pouvez retourner les fonds sur le compte de l'expéditeur, moins les frais de remboursement :"])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transaction"])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pour un montant de"])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["présente un risque AML"])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["par conséquent, la commission a été recalculée"])},
        "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ainsi, le montant à créditer est"])},
        "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucune nouvelle entrée trouvée"])},
        "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date de paiement"])},
        "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remboursement"])}
      }
    }
  }
}