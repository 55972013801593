import { createRouter, createWebHistory } from 'vue-router'
import Login from '../views/Login.vue'
import Unexpected from '../views/Unexpected.vue'
import Test from '../views/Test.vue'

const routes = [
  {
    path: '/test',
    name: 'Test',
    component: Test,
  },
  {
    path: '/',
    name: 'Login',
    component: Login,
  },
  {
    path: '/reg',
    name: 'Login_reg',
    component: Login,
  },
  { path: '/:pathMatch(.*)*',
    component: Unexpected 
  },
]

const router = createRouter({
  mode: 'history',
  history: createWebHistory(),
  routes
})

export default router
