<template>
  <div>
    <h1>ForTests</h1>
    <button v-on:click="this.do_something">Do something</button>
    
    <div>
      <input type="text" v-model="this.action">
      <input type="text" v-model="this.method">

      <div class="mb-1">Form</div>

      <form :method="this.method" target="_blank" :action="this.action">
        <input type="text" name="creq" value="aHR0cHM6Ly95YW5kZXgucnU=">
        <button type="submit">Отправить</button>
      </form>
   </div>
  </div>
</template>

<script>

export default {
    data() {
      return {
        action: 'https://gate-a.paypoint.pro/401/test_bank_acs.php',
        method: 'post',
      }
    },
    methods: {
        do_something() {
          
        } 
    }
}
</script>
<style lang="scss">
  .class {
    display: flex;
    flex-direction: column;
  }
</style>