<template>
    <div class='content-reg d-flex justify-content-around align-items-center'>
        <i class='item-i mr-1 close_me x_gray c28 pointer' v-on:click="this.$emit('updateParentState')"></i>
        <div class='custom_sized_reg d-flex col-12 col-sm-11 col-md-12 col-xl-9 flex-column justify-content-around align-items-center'>
        <h2 class='mt-2 mb-5 neue-roman'>{{ $t('components.service.Registration.1') }}</h2>
        <Stages @updateParentState="this.updateStage" class='col-10 mb-3' :stage='this.stage' :stages='this.stages'/>
        <div v-if='this.stage == "0"' class='p-0 d-flex col-10 mb-3 container-fluid flex-column align-items-start neue'>
            <div class='col-12 d-flex flex-column justify-content-between align-items-start flex-sm-row justify-content-sm-between align-items-sm-center'>
                <Button :name="$t('components.service.Registration.2')" :icon_i='this.usertype  == "1" ? "user_4_1_g contain c28" : "user_4_1 contain c28"' 
                    class='col-12 w_45 p-2 custom_reg_mb' v-on:click='() => {this.typeChange(), this.usertype = "1", this.stages[0].ready = true}' 
                    :type='this.usertype  == "1" ? "btn-dashed-active" : "btn-dashed"'/>
                <Button :name="$t('components.service.Registration.3')" :icon_i='this.usertype  == "2" ? "investment_1_g contain c28" : "investment_1 contain c28"' 
                    class='col-12 w_45 p-2' v-on:click='() => {this.typeChange(), this.usertype = "2", this.stages[0].ready = true}' 
                    :type='this.usertype  == "2" ? "btn-dashed-active" : "btn-dashed"'/>
            </div>
        </div>
        <div v-if='this.stage == "1"' class='p-0 col-10 container-fluid'>
            <div v-if='this.usertype == "1" && this.reg_name == true' class='p-0 d-flex col-12 container-fluid flex-column align-items-start'>
                <span class='custom_mb'>{{ $t('components.service.Registration.4') }}</span>
                <div class='mb-3 col-12 d-flex flex-row justify-content-between align-items-center'>
                    <InputSimple id="surname" :dataValue='this.data.surname' :invalid='this.inpts.surname.warn' @blurInput='this.blurCheck' :success='this.inpts.surname.success' :invalidHint="$t('components.service.Registration.8')" :placeholder="$t('components.service.Registration.9')" @updateParentState="this.updateState"/>
                </div>
            </div>
            <div v-if='this.usertype == "1" && this.reg_name == true' class='p-0 d-flex col-12 container-fluid flex-column align-items-start'>
                <span class='custom_mb'>{{ $t('components.service.Registration.5') }}</span>
                <div class='mb-3 col-12 d-flex flex-row justify-content-between align-items-center'>
                    <InputSimple id="firstname" :dataValue='this.data.firstname' :invalid='this.inpts.firstname.warn' @blurInput='this.blurCheck' :success='this.inpts.firstname.success' :invalidHint="$t('components.service.Registration.10')" :placeholder="$t('components.service.Registration.11')" @updateParentState="this.updateState"/>
                </div>
            </div>
            <div v-if='this.usertype == "1" && this.reg_name == true' class='p-0 d-flex col-12 container-fluid flex-column align-items-start'>
                <span class='custom_mb'>{{ $t('components.service.Registration.6') }}</span>
                <div class='mb-3 col-12 d-flex flex-row justify-content-between align-items-center'>
                    <InputSimple id="patronymic" :dataValue='this.data.patronymic' :invalid='this.inpts.patronymic.warn' @blurInput='this.blurCheck' :success='this.inpts.patronymic.success' :invalidHint="$t('components.service.Registration.10')" :placeholder="$t('components.service.Registration.12')" @updateParentState="this.updateState"/>
                </div>
            </div>
            <div v-if='this.usertype == "1"' class='p-0 d-flex col-12 container-fluid flex-column align-items-start'>
                <span class='custom_mb'>{{ $t('components.service.Registration.36') }}</span>
                <div class='mb-3 col-12 d-flex flex-row justify-content-between align-items-center'>
                    <SelectSimple
                            id='fact_country' 
                            :keywords.sync='this.countries_keywords'
                            :dataValue='this.data.fact_country'
                            :isSearch="true"
                            :hotUpdate="true"
                            @updateParentState="this.updateState" 
                        />
                </div>
            </div>
            <div v-if='this.usertype == "2"' class='p-0 d-flex col-12 container-fluid flex-column align-items-start'>
                <span class='custom_mb'>{{ $t('components.service.Registration.36') }}</span>
                <div class='mb-3 col-12 d-flex flex-row justify-content-between align-items-center'>
                    <SelectSimple
                            id='fact_country' 
                            :keywords.sync='this.countries_keywords'
                            :dataValue='this.data.fact_country'
                            :isSearch="true"
                            :hotUpdate="true"
                            @updateParentState="this.updateState" 
                        />
                </div>
                <span class='custom_mb'>{{ $t('components.service.Registration.7') }}</span>
                <div class='mb-3 col-12 d-flex flex-row justify-content-between align-items-center'>
                    <InputSimple id="uname" :dataValue='this.data.uname' :invalid='this.inpts.uname.warn' @blurInput='this.blurCheck' :success='this.inpts.uname.success' :invalidHint="$t('components.service.Registration.13')" :placeholder="$t('components.service.Registration.14')"  @updateParentState="this.updateState"/>                
                </div>
                <span class='custom_mb'>{{ $t('components.service.Registration.35') }}</span>
                <div class='mb-3 col-12 d-flex flex-row justify-content-between align-items-center'>
                    <InputSimple id="legal_entity_identifier" :dataValue='this.data.legal_entity_identifier' :invalid='this.inpts.legal_entity_identifier.warn' @blurInput='this.blurCheck' :success='this.inpts.legal_entity_identifier.success' :invalidHint="$t('components.service.Registration.13')"  @updateParentState="this.updateState"/>                
                </div>
            </div>
            <div v-if="this.usertype" class='p-0 mt-3 d-flex col-12 container-fluid flex-column align-items-center neue'>
                <div class='mb-3 col-12 d-flex flex-column justify-content-between align-items-start flex-sm-row justify-content-sm-between align-items-sm-center'>
                    <Button :name="$t('components.service.Registration.15')" class='col-12 w_45 p-2 custom_reg_mb'
                     v-on:click='this.changeIType("Телефон")'
                     :icon_i='this.iType  == "Телефон" ? "phone_g contain c28" : "phone contain c28"' 
                     :type='this.iType == "Телефон" ? "btn-dashed-active" : "btn-dashed"'/>
                    <Button :name="$t('components.service.Registration.16')" class='col-12 w_45 p-2'
                     v-on:click='this.changeIType("Email")'
                     :icon_i='this.iType  == "Email" ? "email_g contain c28" : "email contain c28"' 
                     :type='this.iType == "Email" ? "btn-dashed-active" : "btn-dashed"'/>
                </div>
            </div>
            <div v-if="this.iType == 'Телефон'" class='p-0 d-flex col-12 container-fluid flex-column align-items-start'>
                <span class='custom_mb'>{{ $t('components.service.Registration.15') }}</span>
                <div class='mb-3 col-12 d-flex flex-row justify-content-between position-relative align-items-center'>
                    <PhoneCode id="mobilenumber" :dataValue='this.data.mobilenumber' :val='this.data.mobilenumber' :placeholder='"+7 800 000 00 00"' :invalid='this.warn' :success='this.success' :invalidHint='this.warnHint' @updateParentState="this.updateState"/>    
                    <loading :active="this.loader"
                        :is-full-page="false"
                        :height='30'
                        :width='30'
                        :loader='"spinner"'
                        :color='"#008D3E"'
                        :opacity='0'
                    />
                </div>
            </div>
            <div v-if="this.iType == 'Email'" class='p-0 d-flex col-12 container-fluid flex-column align-items-start'>
                <span class='custom_mb'>{{ $t('components.service.Registration.16') }}</span>
                <div class='mb-3 col-12 d-flex flex-row justify-content-between position-relative align-items-center'>
                    <InputSimple id="email" :dataValue='this.data.email' :placeholder='"example@some.dom"' :invalid='this.warn' :success='this.success' :invalidHint='this.warnHint' @updateParentState="this.updateState"/>    
                    <loading :active="this.loader"
                        :is-full-page="false"
                        :height='30'
                        :width='30'
                        :loader='"spinner"'
                        :color='"#008D3E"'
                        :opacity='0'
                    />
                </div>
            </div>
        </div>
        <div v-if="this.stage == '2'" class='p-0 col-10 container-fluid'>
         <!-- <div class='p-0 container-fluid'> -->
            <div class='p-0 d-flex col-12 container-fluid flex-column align-items-start'>
                <span class='custom_mb'>{{ $t('components.service.Registration.17') }}</span>
                <div class='mb-3 col-12'>
                    <InputSimple id="password" type='password' :dataValue='this.data.password' @blurInput='this.blurCheck' :success='this.inpts.password.success && this.safety > 20 && this.data.password.length > 0' :invalid='this.inpts.password.warn' :invalidHint="$t('views.Safety.Password.7') + ' !.@%^*-_=#~$'" @updateParentState="this.updateState"/>
                </div>
            </div>
            <div class='p-0 d-flex col-12 container-fluid'>
                <div class='mb-3 col-12 d-flex flex-row justify-content-between align-items-center'>
                    <div class="progress">
                        <div class="progress-bar" :class="{
                            'bg-danger': this.safety <= 20,
                            'bg-warning': this.safety > 20 && this.safety <= 60,
                            'bg-success': this.safety > 60,
                        }"
                         role="progressbar" :style="`width: ${this.safety}%;`"></div>
                    </div>
                </div>
            </div> 
            <div class='p-0 d-flex col-12 container-fluid flex-column align-items-start'>
                <span class='mb-3 custom_mb'>{{ $t('components.service.Registration.19') }}</span>
                <div class='col-12'>
                    <InputSimple id="rep_password" type='password' :dataValue='this.data.rep_password' @blurInput='this.blurCheck' :invalid='this.rep_pas_warn' :invalidHint="$t('components.service.Registration.20')" :success='this.rep_pas_success' @updateParentState="this.updateState"/>
                </div>
            </div>
        </div>
        <div class='d-flex p-0 container-fluid col-10 col-sm-6 flex-row align-items-center justify-content-between mx-auto mt-4 mb-4'>
            <Button :name="$t('components.service.Registration.21')" class='w_45 full' v-on:click='this.goBack()' type='btn-outline-warning'/>
            <Button v-if='this.stage == "2"' :name="$t('components.service.Registration.22')" class='w_45 full' :disabled='this.stages[this.stage].ready ? false : true' v-on:click='this.goNext()' type='btn-warning'/>
            <Button v-else :name="$t('components.service.Registration.23')" class='w_45 full' :disabled='this.stages[this.stage].ready ? false : true' v-on:click='this.goNext()' type='btn-warning'/>
        </div>
        </div>
    </div>
</template>

<script>
import InputSimple from '@/components/service/InputSimple';
import Button from '@/components/service/Button';
import Dropdown from '@/components/service/Dropdown';
import PhoneCode from '@/components/service/PhoneCode';
import Stages from '@/components/virtAct/Stages';
import Loading from 'vue-loading-overlay';
import SelectSimple from '@/components/service/SelectSimple';
import 'vue-loading-overlay/dist/css/index.css';

export default {
    components: {
        InputSimple,
        Button,
        Dropdown,
        PhoneCode,
        Stages,
        Loading,
        SelectSimple 
    },
    data() {
        return {
            stages: [
                {stage: 0, ready: false, name: this.$t('components.service.Registration.24')},
                {stage: 1, ready: false, name: this.$t('components.service.Registration.25')},
                {stage: 2, ready: false, name: this.$t('components.service.Registration.26')},
            ],
            stage: 0,
            usertype: false,
            continue: false,
            timeout: false,
            warn: false,
            w_time: false,
            rep_pas_warn: false,
            rep_pas_success: false,
            success: false,
            loader: false,
            safety: 0,
            iType: 'Email',
            warnHint: 'Unexpected warning',
            reg_name: false,
            countries_keywords: [],
            regExp: {
                surname: /^[а-яёЁА-Я\-\`\']{1,300}$/, // при изменении - скопировать в php
                firstname: /^[а-яёЁА-Я\-\`\']{1,300}$/, // при изменении - скопировать в php
	            uname: /^(?!\s*$).+/,
	            patronymic: /^[а-яёЁА-Я\s\-\`\']{1,300}$/,
                email: /^((([A-Za-z]|[0-9]|!|#|\$|%|&|'|\*|\+|\-|\/|=|\?|\^|_|`|\{|\||\}|~)+(\.([A-Za-z]|[0-9]|!|#|\$|%|&|'|\*|\+|\-|\/|=|\?|\^|_|`|\{|\||\}|~)+)*)@((((([A-Za-z]|[0-9])([a-z]|[0-9]|\-){0,61}([A-Za-z]|[0-9])\.))*([A-Za-z]|[0-9])([A-Za-z]|[0-9]|\-){0,61}([A-Za-z]|[0-9])\.)[\w]{2,30}|(((([0-9]){1,3}\.){3}([0-9]){1,3}))|(\[((([0-9]){1,3}\.){3}([0-9]){1,3})\])))$/,
                mobilenumber: /^\+?[0-9]{11,15}$/i,
                password: /^[0-9a-zA-Z_\-\.\!\@\$\%\^\*\=\#\~]{4,63}$/,
                legal_entity_identifier: /^(?!\s*$).+/
            },
            inpts: {
                surname: {
                    warn: false,
                    success: false,
                    time: false,
                },
                firstname: {
                    warn: false,
                    success: false,
                    time: false,
                },
                patronymic: {
                    warn: false,
                    success: false,
                    time: false,
                },
                uname: {
                    warn: false,
                    success: false,
                    time: false,
                },
                legal_entity_identifier: {
                    warn: false,
                    success: false,
                    time: false,
                },
                password: {
                    warn: false,
                    success: false,
                    time: false,
                },
            },
            data: {
                surname: '',
                firstname: '',
                patronymic: '',
                email: '',
                mobilenumber: '',
                password: '',
                rep_password: '',
                uname: '',
                safety: 0,
                legal_entity_identifier: '',
                fact_country: ''
            }
        }
    },
    mounted() {
        this.reg_name = this.$store.state.config["REGISTER_WITH_NAME"];
        if (this.$route.query.usertype) {
            this.typeChange(); 
            if (this.$route.query.usertype == 'natural') {
                this.usertype = "1"; 
                this.stages[0].ready = true;
                this.goNext();
            } else if (this.$route.query.usertype == 'legal') {
                this.usertype = "2"; 
                this.stages[0].ready = true;
                this.goNext();
            }
        }
    },
    methods: {
        updateState(state) {
            this.data[state.id] = state.value;
            if (state.id == 'password') {
                this.count_pass_safety();
            }
            if (state.id == 'email' || state.id == 'mobilenumber') {
                this.checkValue(state.value, state.id);
            };
            this.checkParams();
        },
        getCountryCode() {
            this.axios.get("http://ip-api.com/json/?fields=57347").then(res => {
                let country = false
                if (res && res.data.status == 'success') {
                    country = res.data.countryCode
                }
                this.axios.post('GetlistCountries').then(res => {
                    if (res.data.body.result == '0') {
                        let list = [];
                        res.data.body.tables[0].colvalues.forEach(e => {
                            if (e.iso_code2 == country) {
                                this.data.fact_country = e.code;
                            }
                            e.value = e.country;
                            list.push(e)
                        });
                        this.countries_keywords = list;
                    }
                })
            })
        },
        blurCheck(state) {
            if (!this.checkReg(state)) {
                return;
            };
            if (state.id == 'email' || state.id == 'mobilenumber') {
                this.checkValue(state.value, state.id);
            };
            this.checkParams();
        },
        goBack() {
            if (this.stage == '0') {
                this.$emit('updateParentState');
            } else {
                this.changeStage(-1);
            }
        },
        goNext() {
            if (this.stage == '2') {
                this.doRegister()
            } else {
                this.changeStage(+1);
            }
        },
        changeStage(i, s = this.stage) {
            if (s === false) {
                this.stage = false;
                return;
            }
            if (s + i < 0 || s + i > 2) {
                return;
            }

            if (!this.stages[this.stage].ready && s + i > this.stage) {
                return;
            }
            // if (this.stage == 0 && this.usertype) {
            //     this.stages[this.stage].ready = true;
            // } else if (this.stage == 1 && this.success) {
            //     this.stages[this.stage].ready = true;
            // } else if (this.stage == 2 && this.checkParams()) {
            //     this.stages[this.stage].ready = true;
            // } else {
            //     this.stages[this.stage].ready = false;
            //     return;
            // }
            this.stage = s + i;
        },
        updateStage(i) {
            this.changeStage(0, i)
        },
        checkReg(state) {
            if (this.regExp[state.id]) {
                this.warnHint = this.$t('components.service.Registration.27');
                if (this.regExp[state.id].test(state.value)) {
                    if (state.id == 'email' || state.id == 'mobilenumber') {
                        this.warn = false;
                    } else {
                        this.inpts[state.id].warn = false;
                        this.inpts[state.id].success = true;
                    }
                    return true;
                } else { 
                    if (state.id == 'email' || state.id == 'mobilenumber') {
                        this.warn = true;
                        this.success = false;
                    } else {
                        this.inpts[state.id].warn = true;
                        this.inpts[state.id].success = false;
                    }
                    return false;
                }
            } else {
                return true;
            }
        },
        typeChange() {
            this.warn = false;
            this.success = false;
            this.timeout = false;
            this.safety = 0;
            this.rep_pas_warn = false,
            this.rep_pas_success = false,
            this.data = {
                surname: '',
                firstname: '',
                patronymic: '',
                email: '',
                mobilenumber: '',
                password: '',
                rep_password: '',
                uname: '',
                legal_entity_identifier: '',
                fact_country: ''
                
            }
            this.getCountryCode();
            this.checkParams();
        },
        changeIType(a) {
            this.iType = a;
            this.data.email = '';
            this.data.mobilenumber = '';
            this.warn = false;
            this.success = false;
            // this.data.password = '';
            this.stages[1].ready = false;
            // this.stages[2].reday = false;
        },
        parseUser(val) {
	        let text = val.replace(/\s+/g, "");
	
	        if ( /^[-._a-z0-9]+@(?:[a-z0-9][-a-z0-9]+\.)+[a-z]{2,6}$/i.test(text) ) {
		        return "email";
	        } else if ( /^\+?\d{10,14}$/.test(text) ) { // с плюсом однозначно номер телефона
		        return "mobilenumber";
	        } else {
		        return "";
	        }
        },
        checkValue(arg, type, t = 700, modal = false) {
            // let type = this.parseUser(arg); 
            if (type != '') {
                this.searchMember(arg, t, type);
                this.checkParams();
            } else {
                this.checkParams();
                this.warn = false;
                this.success = false;
            }
        },
        searchMember(arg, t, type) {
            if (this.timeout) {
                window.clearTimeout(this.timeout);
            }
            this.warn = false;
            this.success = false;
            this.timeout = setTimeout(() => {
                this.member = false;
                let data = {};
                data[type] = arg;
                this.loader = true;
                this.axios.post('checkUnique', {no_loader: true, data: data}).then(res => {
                    this.loader = false;
                    if (res.data.body.result != '0') {
                        this.warnHint = res.data.body.result_text;
                        this.warn = true;
                        this.success = false;
                        return;
                    } else {
                        this.warn = false;
                        this.success = true;
                        this.stages[1].ready = true;
                    }
                });
            }, t)
        },
        count_pass_safety() {
            var reg_pass = /^[0-9a-zA-Z_\-\.\!\@\$\%\^\*\=\#\~]{4,63}$/;
	        let value = this.data.password;
	        let safety = 0;
	        if ( value.length < 4 ) {
		        var max_safety = 20;
            } else if (!reg_pass.test(value)) {
                var max_safety = 20;
                this.inpts.password.warn = true;
            } else {
                this.inpts.password.warn = false;
		        var max_safety = 100;
            }
	        safety = value.length * 5;
	
	        for ( var i=1; i<value.length; i++ )
		        if ( value.charAt(i) == value.charAt(i-1) ) 
			        safety -= 4;

	        if ( safety > 50 ) safety = 50; 
	
	        if ( /[A-Z]/.test(value) )		safety += 25;
	        if ( /[a-z]/.test(value) )		safety += 25;
	        if ( /[0-9]/.test(value) )		safety += 25;
	        safety -= 25; // что-то одно по-любому есть
	
	        if ( /\_/.test(value))			safety += 10;
	        if ( /\-/.test(value))			safety += 10;
	        if ( /\./.test(value))			safety += 10;
	        if ( /\!/.test(value))			safety += 10;
	        if ( /\@/.test(value))			safety += 10;
	        if ( /\$/.test(value))			safety += 10;
	        if ( /\%/.test(value))			safety += 10;
	        if ( /\^/.test(value))			safety += 10;
	        if ( /\*/.test(value))			safety += 10;
	        if ( /\=/.test(value))			safety += 10;
            if ( /\#/.test(value))			safety += 10;
            if ( /\~/.test(value))			safety += 10;

	        if ( safety > 100 ) safety = 100; 
	
	        safety = Math.min.apply(null, [max_safety,safety]);
	        this.safety = safety;
        },
        checkParams() {
            if (this.usertype == '1') {
                if (this.success) {
                    this.stages[1].ready = true;
                } else {
                    this.stages[1].ready = false;
                }
            } else if (this.usertype == '2') {
                if (this.data.uname && this.success && this.data.legal_entity_identifier) {
                    this.stages[1].ready = true;
                } else {
                    this.stages[1].ready = false;
                }
            }

            if (this.safety > 20 && this.checkRepPassword()) {
                this.stages[2].ready = true;
            } else {
                this.stages[2].ready = false;
            }
        },
        checkRepPassword() {
            if (this.data.password.length >= 5) {
                if (this.data.password === this.data.rep_password) {
                    this.rep_pas_warn = false;
                    this.rep_pas_success = true;
                    return true;
                }
            }
            if (this.data.rep_password.length > 0) {
                this.rep_pas_warn = true;
                this.rep_pas_success = false;
            }

            return false
        },
        doRegister() {
            let data = {};
            console.log(this.data)
            for(let key in this.data) {
                if (this.data[key] != '') {
                    data[key] = this.data[key];
                }
            }
            data['usertype'] = this.usertype;
            delete data['safety'];
            delete data.rep_password;
            this.axios.post('registerMe', {data: data}).then(res => {
                if (res.data.body.result == '0') {
                    let content = '<p class="p-0">'+this.$t('components.service.Registration.28')+'.</p><p class="p-0">'+this.$t('components.service.Registration.29')+' <a href="/">'+this.$t('components.service.Registration.30')+'</a>.</p>';
                    if (res.data.body.type == 'MANUAL') {
                        content = '<p class="p-0">'+this.$t('components.service.Registration.28')+'.</p><p class="p-0">'+this.$t('components.service.Registration.31')+'.</p>';
                    } else if (res.data.body.type == 'EMAIL') {
                        content = '<p class="p-0">'+this.$t('components.service.Registration.33')+' <span class="text-info"> ' + this.data.email + ' </span>'+this.$t('components.service.Registration.32')+'.</p>';
                    } else if (res.data.body.type == 'NUMBER') {
                        content = '<p class="p-0">'+this.$t('components.service.Registration.28')+'.</p><p class="p-0">'+this.$t('components.service.Registration.29')+' <a href="/">'+this.$t('components.service.Registration.30')+'</a>.</p><p class="p-0">'+this.$t('components.service.Registration.34')+'.</p>';
                    }
                    this.$store.commit('MODAL_ACTION', true);
                    this.$store.commit('MODAL_TYPE', 'answer');
                    this.$store.commit('MODAL_CONTENT', {result: res.data.body.result, result_text: content});
                    this.$emit('updateParentState');
                }
            })
        }
    },
}
</script>

<style lang="scss" scoped>
.progress {
    width: 100%;
}
.close_me {
    position: absolute;
    top: 10px;
    right: 0;
    background-size: contain;
}
.content-reg {
    background-color: #FFFFFF;
    box-shadow: 1px 2px 4px 1px rgb(1 52 23 / 10%);
    border-radius: 0 12px 12px 0;
    @media (max-width: 767px) {
        border-radius: 0;
    }
    position: relative;
}
.full {
    width: 47%;
}
.w_45:not(.full) {
    @media (min-width: 576px) {
        width: 47%;
    }
}
.custom_sized_reg {
    max-width: 700px;
    @media (min-width: 1200px) and (max-width: 1500px)  {
        width: 90% !important;
    }
}
.custom_mb {
    margin-bottom: 0.1rem !important;
}
.custom_reg_mb {
    @media (max-width: 576px) {
        margin-bottom: 1.4rem;
    }
}
.vld-overlay {
    height: 38px;
    justify-content: end;
    right: 5px;
}
</style>