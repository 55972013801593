<template>
    <div class="light">
        <div class='d-flex justify-content-between neue'>
            <h4>{{ $t('components.service.modal.Address.1') }}</h4>
            <i class='big item-i x_red pointer' v-on:click='this.close'></i>
        </div>
        <div class='container-fluid mt-1 p-0 g-3'>
            <div class='' v-for="(row, index) in this.control_13" :key="row.code">
                <!-- <h5 v-if="row.code == 'region'" class="neue-roman text-center pt-3 mb-0">{{ $t('components.service.modal.Address.2') }}</h5>
                <h5 v-if="row.code == 'street'" class="neue-roman text-center pt-3 mb-0">{{ $t('components.service.modal.Address.3') }}</h5> -->
                <div v-if='this.content.addr["address_" + this.content.field.alias +"_" + row.code]' class="row container-fluid mr-0 ml-0 mt-3 p-0 g-3 align-items-center input" >
                    <div class="col-sm-3 p-0 mt-0">
                        <label class="col-form-label neue input-label pt-0 pb-0">{{row.name}}</label>
                    </div>
                    <div v-if="row.code=='housing' || row.code=='building'" class="col-sm-9 p-0 input-wrapper mt-0">
                        <SelectSimple :id="this.content.field.alias + '_' + row.code" 
                        :keywords.sync='this.content.addr["address_" + this.content.field.alias +"_" + row.code]' 
                        :hotUpdate='true' 
                        :dataValue='this.content.addr["address_" + this.content.field.alias + "_" + row.code + "_checked"]'  
                        @updateParentState="(val) => {this.getAddress(val, row.code, index)}"/>
                    </div>
                    <div class="col-sm-9 p-0 input-wrapper mt-0">
                        <SelectSimple :id="this.content.field.alias + '_' + row.code" 
                        :keywords.sync='this.content.addr["address_" + this.content.field.alias +"_" + row.code]' 
                        :hotUpdate='true' 
                        :disable='this.content.field.verification == 1 
                        || (row.code != "country" && this.content.addr["address_" + this.content.field.alias +"_" + row.code].length == 0) 
                        || row.code == "postcode" ? true : false'
                        :dataValue='this.content.addr["address_" + this.content.field.alias + "_" + row.code + "_checked"]'  
                        @updateParentState="(val) => {this.getAddress(val, row.code, index)}"/>
                    </div>
                </div>
            </div>
            <div class="container-fluid mt-2 d-flex  align-items-center justify-content-center">
                <Button :name="$t('components.service.modal.Address.4')" type='btn-outline-warning' v-on:click="this.close"/>
                <Button :name="$t('components.service.modal.Address.5')" class="ml-2" type='btn-outline-warning' :disabled="this.disable" v-on:click="this.save"/>
            </div>
        </div>
    </div>
</template>

<script>
import Button from '@/components/service/Button';
import InputSimple from '@/components/service/InputSimple';
import SelectSimple from '@/components/service/SelectSimple';


export default {
    components: {
        Button,
        SelectSimple,
        InputSimple
    },
    props: {
        close: [Function],
    },
    data () {
        return {
            content: this.$store.state.modal.content,
            control_13: {
                0: {name: this.$t('components.service.modal.Address.6'), code: 'country'},//
	            1: {name: this.$t('components.service.modal.Address.7'), code: 'region'},//
	            2: {name: this.$t('components.service.modal.Address.8'), code: 'district'},//
	            3: {name: this.$t('components.service.modal.Address.9'), code: 'area'},//
	            4: {name: this.$t('components.service.modal.Address.10'), code: 'city'},//
	            5: {name: this.$t('components.service.modal.Address.11'), code: 'intercity'},//
	            6: {name: this.$t('components.service.modal.Address.12'), code: 'locality'},//
	            7: {name: this.$t('components.service.modal.Address.13'), code: 'street'},//
	            8: {name: this.$t('components.service.modal.Address.14'), code: 'additional'},//
	            9: {name: this.$t('components.service.modal.Address.15'), code: 'additional_slave'},//
	            10: {name: this.$t('components.service.modal.Address.16'), code: 'house'},
	            // 11: {name: 'Корпус', code: 'housing'},
	            // 12: {name: 'Строение', code: 'building'},
	            13: {name: this.$t('components.service.modal.Address.17'), code: 'postcode'}
            },
            data : {},
            disable: true,
        }
    },
    created() {
        console.log(this.content);
    },
    mounted() {
        
    },
    beforeUnmount() {

    },
    methods: {
        save() {
            this.content.updateState({
                    id: this.content.field.alias,
                    value: this.content.addr["address_" + this.content.field.alias + "_" + "house_checked"]
                }, this.content.addr["address_" + this.content.field.alias + "_" + "extra"][0]['value']);
            this.close();
        },
        getAddress(val, key, index) {
            // console.log(val);
            // console.log(key);
            if (val.repeat) {
                return;
            }
            let params = {};
            params[key] = val.value;
            // console.log(val);
            this.content.addr["address_" + this.content.field.alias + "_" + key + "_checked"] = val.value; 
            if (key == 'housing' || key == 'building') {
                return;
            }
            this.axios.post('getlistAddress', {data: params}).then(res => {
                let tables = res.data.body.tables;
                for (let i = 0; i < tables.length; i++) {
                    let name = tables[i].name;
                    if (tables[i].name == 'additionals') {
                        name = 'additional';
                    } else if (tables[i].name == 'additionals_slave') {
                        name = 'additional_slave';
                    } else if (tables[i].name == 'areas') {
                        name = 'area';
                    } else if (tables[i].name == 'cities') {
                        name = 'city';
                    } else if (tables[i].name == 'countries') {
                        name = 'country';
                    } else if (tables[i].name == 'districts') {
                        name = 'district';
                    } else if (tables[i].name == 'streets') {
                        name = 'street'
                    } else if (tables[i].name == 'regions') {
                        name = 'region';
                    } else if (tables[i].name == 'localities') {
                        name = 'locality';
                    } else if (tables[i].name == 'intercitys') {
                        name = 'intercity';
                    } 

                    if (i > index ) {
                        this.content.addr["address_" + this.content.field.alias + "_" + name + "_checked"] = ''; 
                        if (tables[i].colvalues.length > 0) {
                            this.content.addr["address_" + this.content.field.alias +"_" + name] = tables[i].colvalues;
                        }
                    }

                }

                if (key == 'house') {
                        this.content.addr["address_" + this.content.field.alias + "_" + "postcode"] = [];
                        this.content.addr["address_" + this.content.field.alias + "_" + "postcode_checked"] = this.content.addr["address_" + this.content.field.alias + "_" + "house_checked"];
                        this.content.addr["address_" + this.content.field.alias + "_" + "house"].forEach(e => {
                            this.content.addr["address_" + this.content.field.alias + "_" + "postcode"].push({code: e.code, name: e.postcode});
                        });
                        this.disable = false;
                }

                    
                console.log(this.content.addr);
            })
        }
    },
}
</script>