import { createStore } from 'vuex';
import axios from 'axios';
import i18n from './../i18n';

export default createStore({
  state: {
    isLogged: false,
    globalKey: 1,
    globalKeyKeyt: 1,
    resize: {
      w: false,
      h: false,
    },
    isMobile: false,
    loader: false,
    error: false,
    fingerprint: false,
    pay_pass: false,
    config: false,
    modal: {
      action: false,
      type: false,
      content: false,
    },
    darkWrapper: false,
    title: '',
    titleHint: false,
    userData: false,
    menu: false,
    webView: false,
    webViewStatusBar: 0,
    individual: process.env.VUE_APP_INDIVIDUAL ? process.env.VUE_APP_INDIVIDUAL : false,
    logo: {
      img: 'logoDefault.svg',
      style: '',
    }
  },
  mutations: {
    IS_LOGGED: (state, n) => {
      state.isLogged = n;
    },
    GLOBAL_KEY: (state) => {
      state.globalKey = state.globalKey + 1;
    },
    GLOBAL_KEY_KEYT: (state) => {
      state.globalKeyKeyt = state.globalKeyKeyt + 1;
    },
    TITLE: (state, n) => {
      state.title = n;
    },
    TITLE_HINT: (state, n) => {
      state.titleHint = n;
    },
    RESIZE: (state, n) => {
      state.resize = n;
    },
    IS_MOBILE: (state, n) => {
      state.isMobile = n;
    },
    FINGERPRINT: (state, n) => {
      state.fingerprint = n;
    },
    PAY_PASS: (state, n) => {
      state.pay_pass = n;
    },
    MODAL: (state, n) => {
      state.modal = n;
    },
    MODAL_ACTION: (state, n) => {
      state.modal.action = n;
    },
    MODAL_TYPE: (state, n) => {
      state.modal.type = n;
    },
    MODAL_CONTENT: (state, n) => {
      state.modal.content = n;
    },
    LOADER: (state, n) => {
      state.loader = n;
    },
    MENU: (state, n) => {
      state.menu = n;
    },
    DARK_WRAPPER: (state, n) => {
      state.darkWrapper = n;
    },
    USER_DATA: (state, n) => {
      if (n.user_info) {
        console.log('user_info: ');
        console.log(n.user_info);
        if (n.user_info.avatar.value.length > 0) {
          n.avatar = n.user_info.avatar.value[0].body;  
        }
      }
      state.userData = n;
      if ( state.isLogged && n.toUrl.url) {
        window.location.href = window.location.protocol + '//' + n.toUrl.url + n.toUrl.request;
        // console.log(window.location)
      }
    },
    USER_DATA_INFO: (state, n) => {
      state.userData.user_info = n;
    },
    USER_DATA_AVATAR: (state, n) => {
      state.userData.avatar = n;
    },
    USER_DATA_TEMPLATES: (state, n) => {
      state.userData.templates = n;
    },
    USER_DATA_PASS: (state, n) => {
      state.userData.pass = n;
    },
    USER_DATA_INFORM: (state, n) => {
      state.userData.inform = n;
    },
    USER_DATA_AU: (state, n) => {
      state.userData.last_au = n;
    },
    USER_DATA_KEYTS: (state, n) => {
      state.userData.keyts = n;
    },
    PRE_CONFIG: (state, n) => {
      state.config = n;
    },
    ERROR: (state, n) => {
      state.error = n;
    },
    LOGO: (state, n)  => {
      if (n.img != false) {
        state.logo.img = n.img;
      }
      if (n.style != '') {
        state.logo.style = n.style;
      }
    },
    WEB_VIEW: (state, n) => {
      state.webView = n;
    },
    WEB_VIEW_STATUS_BAR: (state, n) => {
      state.webViewStatusBar = Number(n);
    }
  },
  actions: {
    logout(context, router) {
      axios.post('logout').then(res => {console.log('logout');
        // context.commit('USER_DATA', false);
      });
    },
    reRender(context) {
      context.commit('GLOBAL_KEY');
      axios.post('reLang', {no_loader: true, data: {}}).then(res => {
        context.commit('LOGO', res.data.body);
      })
    },
    modalAnswer(context, p) {
      context.commit('MODAL_ACTION', true);
      context.commit('MODAL_TYPE', 'answer');
      context.commit('MODAL_CONTENT', {result: p.code,
                                       result_text: p.text});
    },
    informer(context) {
      if (context.getters.get_isLogged) {
          axios.post('Informator', {no_loader: true, data: {del_from_server: '0'}}).then(res => {
            if (!context.getters.get_isLogged || res.data.error) {
              return
            }
            let tmp = {
              'payments': [],
              'messages': [],
              'payrequests': [],
              'support': [],
              'news': [],
            };
            res.data.body.informator.forEach(e => {
              if ( e['subj'] == '3' ) {
                tmp.payments.push(e);
              } else if ( e['subj'] === '2' ) {
                tmp.messages.push(e)
              } else if ( e['subj'] === '1' ) {
                tmp.payrequests.push(e)
              } else if ( e['subj'] === '4' ) {
                tmp.support.push(e)
              } else if ( e['subj'] == '5' ) {
                tmp.news.push(e)
              }
            });
            context.commit('USER_DATA_INFORM', tmp);
            setTimeout(() => {
              context.dispatch('informer');
            }, 60000);
          })
      }
    },
    isLogged(context, p) {
      axios.post('isLogged').then(res => {
        if (res.data.body.user_data) {
          context.commit('USER_DATA', res.data.body.user_data);
          if (res.data.body.lang && !localStorage.getItem("lang")) {
            p.i18n.locale = res.data.body.lang;
            localStorage.setItem("lang", res.data.body.lang);
          }
          if (res.data.body.user_data.config) {
            context.commit('PRE_CONFIG', res.data.body.user_data.config);
            if (res.data.body.user_data.config.PAGE_TITLE) {
              document.title = res.data.body.user_data.config.PAGE_TITLE;
            }
            if (res.data.body.user_data.config.FAVICON) {
              let link = document.querySelector("link[rel*='icon']") || document.createElement('link');
              link.type = 'image/x-icon';
              link.rel = 'shortcut icon';
              link.href = res.data.body.user_data.config.FAVICON;
              document.getElementsByTagName('head')[0].appendChild(link);
            }
          }
        }
        if (res.data.body.logo) {
          context.commit('LOGO', res.data.body.logo);
        }
      })
    },
  },
  modules: {
  },
  getters: {
    get_isLogged (state) {
      return state.isLogged;
    },
    get_keyts (state) {
      if (!state.userData.keyts) {
        return [];
      } else {
        let arr = [];
        state.userData.keyts.forEach(e => {
          arr.push(e);
        });
        return arr;
      }
    },
    get_user_name (state) {
      let str = '';
      if (state.userData.user_info.full_name) {
        str = state.userData.user_info.full_name.value;
      } else if (state.userData.user_info.surname) {
        str = state.userData.user_info.surname.value
        if (state.userData.user_info.firstname.value) {
          str = str + ' ' + state.userData.user_info.firstname.value.charAt(0);
        }
        if (state.userData.user_info.patronymic.value) {
          str = str + '. ' + state.userData.user_info.patronymic.value.charAt(0) + '.';
        }
      }
      return str;
    },
    get_keyts_sum(state) {
      if (!state.userData.keyts) {
        return {};
      }
      let obj = {};
      let arr = [];
      state.userData.keyts.forEach(e => {
        arr.push(e.curr);
      });
      let tmp_arr = Array.from(new Set(arr));
      tmp_arr.forEach(e => {
        state.userData.keyts.forEach(a => {
          if (a.curr == e) {
            if (!obj[e]) {
              obj[e] = 0;
            }
            obj[e] += Number(a.balance);
          }
        });
      });
      let toSort = Object.entries(obj);
      toSort.sort((a, b) => {
        if (a[1] < b[1]) {
          return 1;
        }
        if (a[1] > b[1]) {
          return -1;
        }
        return 0;
      })
      obj = {};
      // let c = 3;
      // if (toSort.length < c) {
      //   c = toSort.length;
      // }
      // console.log(toSort )
      for (let i = 0; i < toSort.length; i++) {
        obj[toSort[i][0]] = toSort[i][1]
      }
      return obj;
    },
    get_sort_keyts(state) {
      let arr = [];
      if (!state.userData.keyts) {
        return [];
      }
      state.userData.keyts.forEach(e => {
        let image = `@/assets/currs/${e.curr.toUpperCase()}.svg`;
        try {
          image = require(`@/assets/currs/${e.curr.toUpperCase()}.svg`);
        } catch (e) {
          if (e.code !== 'MODULE_NOT_FOUND') {
            throw e
          }
          image = '';
        }
          
        e.image = image;
        arr.push(e);
      });
      arr.sort((a, b) => {
        if (Number(a['balance']) < Number(b['balance'])) {
          return 1;
        }
        if (Number(a['balance']) > Number(b['balance'])) {
          return -1;
        }
        return 0;
      })
      return arr;
    },
    get_keyts_keywords(state) {
      if (!state.userData.keyts) {
        return [];
      }
      let arr = [];
      state.userData.keyts.forEach(e => {
        let name = `${e['curr']} ${e['keyt']} ${e['name']} ( ${e['balance']} )`;

        let image = `@/assets/currs/${e.curr.toUpperCase()}.svg`;
          try {
            image = require(`@/assets/currs/${e.curr.toUpperCase()}.svg`);
          } catch (e) {
            if (e.code !== 'MODULE_NOT_FOUND') {
              throw e
            }
            image = '';
          }
          
        arr.push({
           'code': e['keyt'],
           'symbol': e['symbol'],
           'value': e.name, 
           'image': image,
           'curr': e['curr'],
           'precision': e['precision'],
           'curr_code': e['curr_code'],
           'balance': e['balance'],
          });
      });
      return arr;
    }
  }
})
