<template>
    <div class='container-xl container-fluid p-0'>
        <div class="d-flex justify-content-between p-0 mb-2">
          <h4 v-if="this.get_done" class='neue-roman mt-3 d-flex p-0 align-items-center justify-content-start' 
            :class="{'color-green': (Number(this.get_done) >=1 && Number(this.get_done) <= 3) || Number(this.get_done) === 8,
              'color-warning': Number(this.get_done) == -2 || Number(this.get_done) == -3,
              'color-acent': Number(this.get_done) == 0 || (Number(this.get_done) >= 4 && Number(this.get_done) <= 7)
          }">
            <i class='item-i mr-1 c28 contain' :class="{'check': (Number(this.get_done) >=1 && Number(this.get_done) <= 3) || Number(this.get_done) === 8,
              'x_red': Number(this.get_done) == -2 || Number(this.get_done) == -3,
              'clock': Number(this.get_done) == 0 || (Number(this.get_done) >= 4 && Number(this.get_done) <= 7)}"></i>
            {{this.get_done_text}}
          </h4>
          <i class='item-i x_red pointer contain' v-on:click='this.close'></i> 
        </div>
        <PayStatusTemplate 
            :message="this.getMessage()"
            :get_transact="this.get_transact" 
            :get_sum="this.get_sum"
            :get_res="this.get_res"
            :get_done="this.get_done"
            @getParamsExtTransact="this.getParamsExtTransact" 
        >
            <template v-slot:loader>
                <loading :active="this.loader"
                   :is-full-page="false"
                   :height='50'
                   :width='50'
                   :loader='"dots"'
                   :color='"#008D3E"'
                   :opacity='0'
                   />
            </template>

            <template v-slot:links>
                <ul>
                    <li><router-link :to="this.repeat_url" class="href" v-on:click="this.close">{{ $t('components.service.modal.PayStatus.1') }}</router-link></li>
                    <li><router-link :to="'History'" class="href" v-on:click="this.close">{{ $t('components.service.modal.PayStatus.2') }}</router-link></li>
                    <li><router-link :to="'/keyts/list'" class="href" v-on:click="this.close">{{ $t('components.service.modal.PayStatus.3') }}</router-link></li>
                    <li><router-link :to="'/templates'" class="href" v-on:click="this.close">{{ $t('components.service.modal.PayStatus.7') }}</router-link></li>
                </ul>
            </template>
        </PayStatusTemplate>
    </div>
</template>
  
<script>
  import Loading from 'vue-loading-overlay';
  import 'vue-loading-overlay/dist/css/index.css';
  import ClipboardJS from 'clipboard';
  import Button from '@/components/service/Button';
  import PayStatusTemplate from '@/components/service/PayStatusTemplate';
  
  export default {
      components: {
          Loading,
          Button,
          PayStatusTemplate
      },
      data() {
        return {
          result: this.$store.state.modal.content.result,
          result_text: this.$store.state.modal.content.result_text,
          success: this.$route.query.success,
          repeat_url: 'Payin',
          loader: true,
          get_res: false,
          get_sum: false,
          get_transact: false,
          get_done: '0',
          get_done_text: '',
        }
      },
      props: {
        close: [Function],
      },
      methods: {
          getTitle() {
            if (this.result === '0') {
              return this.$t('components.service.modal.PayStatus.4');
            } else if (this.success !== '0') {
              return this.$t('components.service.modal.PayStatus.5');
            }
          },
          getMessage() {
            if (this.result_text) {
              return this.result_text;
            } else {
              return '';
            }
          },
          getParamsExtTransact() {
            this.get_res = false;
            this.get_sum = false;
            this.get_transact = false;
            this.get_done = '0';
            this.get_done_text = '';
            let params = {ext_transact_num : this.$store.state.modal.content.ext_transact,
                              only_data: '1'
                            }
                this.loader = true;
                this.axios.post('GetParamsExtTransact', {no_loader: true, data: params}).then(res => {
                  this.loader = false;
                  console.log('GETPARAMSEXTTRANSACT: ')
                  console.log(res);
                  if (res.data.body.result == '0') {
                    this.get_res = res.data.body.advanced.result_text;
                    this.get_done = res.data.body.advanced.done;
                    this.get_done_text = res.data.body.advanced.done_text;
                    new ClipboardJS('.cli_transact');
                    let presum = res.data.body.advanced.s_sum ? res.data.body.advanced.s_sum : res.data.body.advanced.p_sum;
                    let precur = res.data.body.advanced.s_curr_text ? res.data.body.advanced.s_curr_text : res.data.body.advanced.p_curr_text;
                    this.get_sum = presum  + " " + precur;
                    this.get_transact = res.data.body.advanced.transact;
                  } else {
                    this.get_res = res.data.body.result_text;
                  }
                })
          }
      },
      mounted() {
          if (this.$store.state.modal.content) {
              if (this.$store.state.modal.content.ext_transact) {
                this.getParamsExtTransact();
              }
              if (this.$store.state.modal.content.type == 'pay') {
                this.repeat_url = 'Pay';
              }
              if (this.$store.state.modal.content.type == 'transfer') {
                this.repeat_url = 'transfer_id';
              }
              if (this.$store.state.modal.content.type == 'transfer_self') {
                this.repeat_url = 'transfer_self';
              }
          }
          console.log(this)
      },
  }
  </script>
